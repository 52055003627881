/* eslint-disable camelcase */
import { create } from "axios";

const queryString = require("query-string");
// const urlencode = require("urlencode");

function api(accessToken) {
  const withToken = accessToken
    ? {
        "X-ACCESS-TOKEN": accessToken
      }
    : {};

  const headers = {
    "Access-Control-Allow-Origin": "*",
    ...withToken
  };

  const axios = create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: false,
    config: {},
    headers
  });
  axios.defaults.headers = headers;

  const auth = {};
  const users = {};
  const questions = {};
  const forms = {};
  const results = {};
  const products = {};
  const banners = {};
  const videos = {};
  const comments = {};
  const books = {};
  const boards = {};
  const articles = {};
  const files = {};
  const reports = {};

  auth.signin = async (
    data = {
      email: "이메일, String",
      password: "비밀번호, String"
    }
  ) => {
    try {
      const {
        email, // "이메일, String",
        password // "비밀번호, String"
      } = data;
      const result = await axios({
        method: "post",
        url: "/api/v1/auth/signin",
        data: {
          email,
          password
        }
      });
      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  auth.signup = async (
    data = {
      email: "이메일, String, required",
      password: "비밀번호, String, required",
      name: "성명, String, required",
      mobile: "전화번호, String, required",
      birthday: "전화번호, Date, required",
      profileImage: "프로필 이미지 파일 주소, String, optional",
      gender: "성별('male', 'female', 'other', 'unknown'), String, optional",
      marketing_email: "이메일 수신 여부, Boolean, optional",
      marketing_sms: "SMS 수신 여부, Boolean, optional"
    }
  ) => {
    try {
      const {
        email, // "이메일, String, required",
        password, // "비밀번호, String, required",
        name, // "성명, String, required",
        mobile, // "전화번호, String, required",
        birthday, // "전화번호, Date, required",
        profileImage, // "프로필 이미지 파일 주소, String, optional",
        gender, // "성별('male', 'female', 'other', 'unknown'), String, optional",
        marketing_email, // "이메일 수신 여부, Boolean, optional",
        marketing_sms // "SMS 수신 여부, Boolean, optional"
      } = data;

      const result = await axios({
        method: "post",
        url: "/api/v1/auth/signup",
        data: {
          email,
          password,
          name,
          mobile,
          birthday,
          profileImage,
          gender,
          marketing_email,
          marketing_sms
        }
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  users.list = async (data = { limit: 10, page: 1 }) => {
    try {
      const { limit, page, type, search } = data;

      const query = {
        limit,
        page,
        type,
        search
      };

      const result = await axios({
        method: "get",
        url: "/api/v1/admin/users?" + queryString.stringify(query)
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  users.findOne = async (data = { uid: "" }) => {
    try {
      const { uid } = data;

      const result = await axios({
        method: "get",
        url: `/api/v1/admin/users/${uid}`
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  users.create = async data => {
    try {
      const result = await axios({
        method: "post",
        url: "/api/v1/admin/users",
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  users.update = async (data = { uid: "" }) => {
    try {
      const { uid } = data;
      const result = await axios({
        method: "put",
        url: `/api/v1/admin/users/${uid}`,
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  users.me = async () => {
    try {
      const result = await axios({
        method: "get",
        url: "/api/v1/users/me"
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  questions.list = async (data = { limit: 10, page: 1, type: undefined }) => {
    try {
      const { limit, page, type, search } = data;

      const query = {
        limit,
        page,
        type,
        search
      };

      const result = await axios({
        method: "get",
        url: "/api/v1/admin/test/questions?" + queryString.stringify(query)
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  questions.findOne = async (data = { qid: "" }) => {
    try {
      const { qid } = data;

      const result = await axios({
        method: "get",
        url: `/api/v1/admin/test/questions/${qid}`
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  questions.create = async data => {
    try {
      const result = await axios({
        method: "post",
        url: "/api/v1/admin/test/questions",
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  questions.update = async (data = { qid: "" }) => {
    try {
      const { qid } = data;
      const result = await axios({
        method: "put",
        url: `/api/v1/admin/test/questions/${qid}`,
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  questions.delete = async (data = { qid: "" }) => {
    try {
      const { qid } = data;
      const result = await axios({
        method: "delete",
        url: `/api/v1/admin/test/questions/${qid}`
      });
      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  forms.list = async (data = { type: undefined }) => {
    try {
      const { type } = data;

      const query = {
        type
      };

      const result = await axios({
        method: "get",
        url: "/api/v1/admin/test/forms?" + queryString.stringify(query)
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  forms.findOne = async (data = { fid: "" }) => {
    try {
      const { fid } = data;

      const result = await axios({
        method: "get",
        url: `/api/v1/admin/test/forms/${fid}`
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  forms.create = async data => {
    try {
      const result = await axios({
        method: "post",
        url: "/api/v1/admin/test/forms",
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  forms.update = async (data = { fid: "" }) => {
    try {
      const { fid } = data;
      const result = await axios({
        method: "put",
        url: `/api/v1/admin/test/forms/${fid}`,
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  results.list = async (data = { limit: 10, page: 1, type: undefined }) => {
    try {
      const { limit, page, type, search } = data;

      const query = {
        limit,
        page,
        type,
        search
      };

      const result = await axios({
        method: "get",
        url: "/api/v1/admin/test/results?" + queryString.stringify(query)
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  results.findOne = async (data = { rid: "" }) => {
    try {
      const { rid } = data;

      const result = await axios({
        method: "get",
        url: `/api/v1/admin/test/results/${rid}`
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  results.create = async data => {
    try {
      const result = await axios({
        method: "post",
        url: "/api/v1/admin/test/results",
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  results.update = async (data = { rid: "" }) => {
    try {
      const { rid } = data;
      const result = await axios({
        method: "put",
        url: `/api/v1/admin/test/results/${rid}`,
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  results.delete = async (data = { rid: "" }) => {
    try {
      const { rid } = data;
      const result = await axios({
        method: "delete",
        url: `/api/v1/admin/test/results/${rid}`
      });
      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  products.list = async (data = { limit: 10, page: 1, type: undefined }) => {
    try {
      const { limit, page, type, search } = data;

      const query = {
        limit,
        page,
        type,
        search
      };

      const result = await axios({
        method: "get",
        url: "/api/v1/admin/products?" + queryString.stringify(query)
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  products.findOne = async (data = { rid: "" }) => {
    try {
      const { rid } = data;

      const result = await axios({
        method: "get",
        url: `/api/v1/admin/products/${rid}`
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  products.create = async data => {
    try {
      const result = await axios({
        method: "post",
        url: "/api/v1/admin/products",
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  products.update = async (data = { rid: "" }) => {
    try {
      const { rid } = data;
      const result = await axios({
        method: "put",
        url: `/api/v1/admin/products/${rid}`,
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  products.delete = async (data = { rid: "" }) => {
    try {
      const { rid } = data;
      const result = await axios({
        method: "delete",
        url: `/api/v1/admin/products/${rid}`
      });
      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  banners.list = async (data = { limit: 10, page: 1, type: undefined }) => {
    try {
      const { limit, page, type, search } = data;

      const query = {
        limit,
        page,
        type,
        search
      };

      const result = await axios({
        method: "get",
        url: "/api/v1/admin/banners?" + queryString.stringify(query)
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  banners.findOne = async (data = { bid: "" }) => {
    try {
      const { bid } = data;

      const result = await axios({
        method: "get",
        url: `/api/v1/admin/banners/${bid}`
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  banners.create = async data => {
    try {
      const result = await axios({
        method: "post",
        url: "/api/v1/admin/banners",
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  banners.getGroup = async () => {
    try {
      const result = await axios({
        method: "get",
        url: "/api/v1/admin/banners/list"
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  banners.setGroup = async data => {
    try {
      const result = await axios({
        method: "post",
        url: "/api/v1/admin/banners/list",
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  banners.update = async (data = { bid: "" }) => {
    try {
      const { bid } = data;
      const result = await axios({
        method: "put",
        url: `/api/v1/admin/banners/${bid}`,
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  banners.delete = async (data = { bid: "" }) => {
    try {
      const { bid } = data;
      const result = await axios({
        method: "delete",
        url: `/api/v1/admin/banners/${bid}`
      });
      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  videos.list = async (data = { limit: 10, page: 1, type: undefined }) => {
    try {
      const { limit, page, type, search } = data;

      const query = {
        limit,
        page,
        type,
        search
      };

      const result = await axios({
        method: "get",
        url: "/api/v1/admin/lectures/videos?" + queryString.stringify(query)
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  videos.findOne = async (data = { vid: "" }) => {
    try {
      const { vid } = data;

      const result = await axios({
        method: "get",
        url: `/api/v1/admin/lectures/videos/${vid}`
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  videos.create = async data => {
    try {
      const result = await axios({
        method: "post",
        url: "/api/v1/admin/lectures/videos",
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  videos.update = async (data = { vid: "" }) => {
    try {
      const { vid } = data;
      const result = await axios({
        method: "put",
        url: `/api/v1/admin/lectures/videos/${vid}`,
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  videos.delete = async (data = { vid: "" }) => {
    try {
      const { vid } = data;
      const result = await axios({
        method: "delete",
        url: `/api/v1/admin/lectures/videos/${vid}`
      });
      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  comments.list = async (data = { limit: 10, page: 1, type: undefined }) => {
    try {
      const { limit, page, type, answered, search } = data;

      const query = {
        limit,
        page,
        type,
        answered,
        search
      };

      const result = await axios({
        method: "get",
        url: "/api/v1/admin/lectures/comments?" + queryString.stringify(query)
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  comments.findOne = async (data = { cid: "" }) => {
    try {
      const { cid } = data;

      const result = await axios({
        method: "get",
        url: `/api/v1/admin/lectures/comments/${cid}`
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  comments.create = async data => {
    try {
      const result = await axios({
        method: "post",
        url: "/api/v1/admin/lectures/comments",
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  comments.update = async (data = { cid: "" }) => {
    try {
      const { cid } = data;
      const result = await axios({
        method: "put",
        url: `/api/v1/admin/lectures/comments/${cid}`,
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  comments.delete = async (data = { cid: "" }) => {
    try {
      const { cid } = data;
      const result = await axios({
        method: "delete",
        url: `/api/v1/admin/lectures/comments/${cid}`
      });
      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  books.list = async (data = { limit: 10, page: 1, type: undefined }) => {
    try {
      const { limit, page, type, search } = data;

      const query = {
        limit,
        page,
        type,
        search
      };

      const result = await axios({
        method: "get",
        url: "/api/v1/admin/lectures/books?" + queryString.stringify(query)
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  books.findOne = async (data = { bid: "" }) => {
    try {
      const { bid } = data;

      const result = await axios({
        method: "get",
        url: `/api/v1/admin/lectures/books/${bid}`
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  books.create = async data => {
    try {
      const result = await axios({
        method: "post",
        url: "/api/v1/admin/lectures/books",
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  books.update = async (data = { bid: "" }) => {
    try {
      const { bid } = data;
      const result = await axios({
        method: "put",
        url: `/api/v1/admin/lectures/books/${bid}`,
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  books.delete = async (data = { bid: "" }) => {
    try {
      const { bid } = data;
      const result = await axios({
        method: "delete",
        url: `/api/v1/admin/lectures/books/${bid}`
      });
      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  boards.list = async (data = { limit: 10, page: 1, type: undefined }) => {
    try {
      const { limit, page, type, search } = data;

      const query = {
        limit,
        page,
        type,
        search
      };

      const result = await axios({
        method: "get",
        url: "/api/v1/admin/boards?" + queryString.stringify(query)
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  boards.findOne = async (data = { bid: "" }) => {
    try {
      const { bid } = data;

      const result = await axios({
        method: "get",
        url: `/api/v1/admin/boards/${bid}`
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  boards.create = async data => {
    try {
      const result = await axios({
        method: "post",
        url: "/api/v1/admin/boards",
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  boards.update = async (data = { bid: "" }) => {
    try {
      const { bid } = data;
      const result = await axios({
        method: "put",
        url: `/api/v1/admin/boards/${bid}`,
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  boards.delete = async (data = { bid: "" }) => {
    try {
      const { bid } = data;
      const result = await axios({
        method: "delete",
        url: `/api/v1/admin/boards/${bid}`
      });
      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  articles.list = async (
    data = { bid: "", limit: 10, page: 1, type: undefined }
  ) => {
    try {
      const { limit, page, search, bid } = data;

      const query = {
        limit,
        page,
        search
      };

      const result = await axios({
        method: "get",
        url: `/api/v1/admin/boards/${bid}/articles?${queryString.stringify(
          query
        )}`
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  articles.findOne = async (data = { bid: "", aid: "" }) => {
    try {
      const { bid, aid } = data;

      const result = await axios({
        method: "get",
        url: `/api/v1/admin/boards/${bid}/articles/${aid}`
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  articles.create = async (data = { bid: "" }) => {
    try {
      const { bid } = data;
      const result = await axios({
        method: "post",
        url: `/api/v1/admin/boards/${bid}/articles`,
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  articles.update = async (data = { bid: "", aid: "" }) => {
    try {
      const { bid, aid } = data;
      const result = await axios({
        method: "put",
        url: `/api/v1/admin/boards/${bid}/articles/${aid}`,
        data
      });

      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  articles.delete = async (data = { bid: "", aid: "" }) => {
    try {
      const { bid, aid } = data;
      const result = await axios({
        method: "delete",
        url: `/api/v1/admin/boards/${bid}/articles/${aid}`
      });
      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  files.upload = async _files => {
    try {
      let fileList = _files;
      const formData = new FormData();
      if (!Array.isArray(fileList)) {
        fileList = [fileList];
      }
      fileList.forEach(file => {
        formData.append("files", file);
      });

      const result = await axios({
        method: "post",
        url: "/api/v1/files/upload",
        data: formData
      });
      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  reports.users = async (data = { range: "monthly", type: null }) => {
    try {
      const { range, type } = data;
      const query = {};
      if (range) {
        query.range = range;
      }
      if (!Number.isNaN(parseInt(type, 10))) {
        query.type = type;
      }

      const result = await axios({
        method: "get",
        url: "/api/v1/admin/reports/users?" + queryString.stringify(query)
      });
      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };
  reports.type = async (
    data = {
      start: "2000-01-01",
      end: new Date().toDateString(),
      sort: "total"
    }
  ) => {
    try {
      const { start, end, sort } = data;
      const query = { start, end, sort };

      const result = await axios({
        method: "get",
        url: "/api/v1/admin/reports/types/first?" + queryString.stringify(query)
      });
      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  reports.question = async (
    data = {
      start: "2000-01-01",
      end: new Date().toDateString(),
      sort: "total"
    }
  ) => {
    try {
      const { start, end, sort } = data;
      const query = { start, end, sort };

      const result = await axios({
        method: "get",
        url: "/api/v1/admin/reports/question?" + queryString.stringify(query)
      });
      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  reports.types = async (
    data = {
      start: "2000-01-01",
      end: new Date().toDateString(),
      sort: "total"
    }
  ) => {
    try {
      const { start, end, sort } = data;
      const query = { start, end, sort };

      const result = await axios({
        method: "get",
        url: "/api/v1/admin/reports/types/group?" + queryString.stringify(query)
      });
      return result.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  };

  return {
    auth,
    users,
    questions,
    forms,
    results,
    products,
    banners,
    videos,
    comments,
    books,
    boards,
    articles,
    files,
    reports
  };
}

export default api;
