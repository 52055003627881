import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Link, Route } from "react-router-dom";
import {
  Typography,
  Row,
  Col,
  Input,
  Button,
  Select,
  Table,
  Popconfirm,
  message
} from "antd";

import api from "utils/api";
import Comment from "./Comment";

const comma = require("comma-number");

const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;

const videoTypes = [
  "자기이해",
  "진로탐색",
  "선택과 확신",
  "실행과 달성",
  "진로 재점검",
  "인생계획"
];
const List = inject("auth")(
  inject("routing")(
    observer(({ auth, match, routing }) => {
      const [data, setData] = useState([]);
      const [queryStrings, setQueryStrings] = useState({});
      const [pagination, setPagination] = useState({
        pageSize: 10,
        total: 0
      });
      const [selectedData, setSelectedData] = useState([]);
      async function fetchCommentList(page = 1, limit = 10) {
        const result = await api(auth.user.token).comments.list({
          page,
          limit,
          ...queryStrings
        });

        // "_id": "5dae67cf7006cf29dcb09978",
        // "type": 0,
        // "title": "test",
        // "content": "tteesstt",
        // "created_at": "2019-10-22T02:22:07.844Z",
        // "updated_at": "2019-10-22T02:22:07.844Z",
        // "__v": 0
        console.log(result);
        const newPagination = { ...pagination };
        newPagination.total = result.comments.totalDocs;

        setPagination(newPagination);
        setData(result.comments.docs);
      }

      async function deleteSelectedData() {
        try {
          const promises = selectedData.map(async value => {
            // eslint-disable-next-line no-param-reassign
            await api(auth.user.token).comments.delete({ cid: value._id });
          });

          await Promise.all(promises);
          message.success("완료되었습니다.");
          fetchCommentList(pagination.current, pagination.pageSize);
        } catch (error) {
          message.error(JSON.stringify(error, null, 4));
        }
      }

      useEffect(() => {
        fetchCommentList();
      }, [queryStrings]);
      const columns = [
        {
          title: "영상 코드",
          dataIndex: "vcode",
          key: "vcode",
          render: value => {
            return (
              value || (
                <Text type="secondary" style={{ fontStyle: "italic" }}>
                  (지정되지 않음)
                </Text>
              )
            );
          }
        },
        {
          title: "회원",
          dataIndex: "user",
          key: "user",
          render: user => {
            return (
              <Link to={`/users/${user._id}`}>
                <Text>{user.name}</Text>
                <Text type="secondary">({user.email})</Text>
              </Link>
            );
          }
        },
        {
          title: "내용",
          dataIndex: "content",
          key: "content",
          render: (value, { _id }) => {
            return <Link to={`/comments/${_id}`}>{value}</Link>;
          }
        },
        {
          title: "생성일",
          dataIndex: "created_at",
          key: "created_at",
          render: value => {
            return new Date(value).toLocaleString();
          }
        },
        {
          title: "수정일",
          dataIndex: "updated_at",
          key: "updated_at",
          render: value => {
            return new Date(value).toLocaleString();
          }
        },
        {
          title: "공개 여부",
          dataIndex: "isSecret",
          key: "isSecret",
          render: value => {
            return value ? "비밀" : "공개";
          }
        },
        {
          title: "PK",
          dataIndex: "_id",
          key: "_id"
        }
      ];
      const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          setSelectedData(selectedRows);
        },
        getCheckboxProps: record => ({
          disabled: record.isDropped, // Column configuration not to be checked
          name: record.name
        })
      };

      function handleTableChange(pager, filters, sorter) {
        fetchCommentList(pager.current, pager.pageSize);
      }

      return (
        <>
          <Row gutter={[16, 16]}>
            <Col>
              <Title level={4}>댓글 리스트</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Search
                placeholder="검색... "
                onSearch={value => {
                  const newQueryStrings = { ...queryStrings };
                  newQueryStrings.search = value;
                  setQueryStrings(newQueryStrings);
                }}
              />
            </Col>
            <Col span={18}>
              <Link to={`${match.url}/new`} style={{ visibility: "hidden" }}>
                <Button icon="plus" type="primary">
                  댓글 등록
                </Button>
              </Link>
              &nbsp;
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Select
                style={{ width: "100%" }}
                defaultValue={null}
                onChange={value => {
                  const newQueryStrings = { ...queryStrings };
                  newQueryStrings.type = value;
                  setQueryStrings(newQueryStrings);
                }}
              >
                <Option value={null}>모든 타입</Option>
                {videoTypes.map((value, index) => {
                  return <Option value={index}>{value}</Option>;
                })}
              </Select>
            </Col>
            <Col span={4}>
              <Select
                style={{ width: "100%" }}
                defaultValue={null}
                onChange={value => {
                  const newQueryStrings = { ...queryStrings };
                  newQueryStrings.answered = value;
                  setQueryStrings(newQueryStrings);
                }}
              >
                <Option value={null}>모든 댓글</Option>
                <Option value="true">답글 있음</Option>
                <Option value="false">답글 없음</Option>
              </Select>
            </Col>
            <Col span={4}>
              <Text>전체 {pagination.total}개</Text>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Table
                dataSource={data}
                columns={columns}
                rowSelection={rowSelection}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Popconfirm
                title="정말 삭제하겠습니까?"
                onConfirm={deleteSelectedData}
                okText="예"
                cancelText="아니오"
              >
                <Button icon="delete" type="danger">
                  선택 삭제
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </>
      );
    })
  )
);

export default ({ match }) => (
  <>
    <Route path={`${match.path}`} exact component={List} />
    <Route path={`${match.path}/:cid`} component={Comment} />
  </>
);
