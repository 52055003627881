import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  Form, //
  Input,
  Button,
  Row,
  Col,
  Select,
  Typography,
  Modal,
  Tag,
  InputNumber,
  Upload,
  Icon,
  message
} from "antd";

import api from "utils/api";

const { Text, Title } = Typography;

export default inject("auth")(
  inject("routing")(
    observer(({ match, auth, routing }) => {
      const [formData, setFormData] = useState({});
      const [createMode, setCreateMode] = useState(false);

      async function fetchData(bid) {
        const result = await api(auth.user.token).banners.findOne({ bid });

        if (result.banner) {
          setFormData(result.banner);
        }
      }

      async function saveData() {
        let result = {};

        if (createMode) {
          result = await api(auth.user.token).banners.create(formData);
        } else {
          result = await api(auth.user.token).banners.update({
            ...formData,
            bid: match.params.bid
          });
        }
        console.log(result);
        if (result.banner) {
          message.success("저장되었습니다.");
          routing.push("/banners");
        } else {
          message.error(JSON.stringify(result, null, 2));
        }
      }

      useEffect(() => {
        if (match.params.bid === "new") {
          setCreateMode(true);
        } else {
          fetchData(match.params.bid);
        }
      }, []);

      function handleChange(e) {
        const { value, name } = e.target;
        const newFormData = { ...formData };
        newFormData[name] = value;
        setFormData(newFormData);
      }

      function setStateKeyValue(key, value) {
        const newFormData = { ...formData };
        newFormData[key] = value;
        setFormData(newFormData);
      }

      async function uploadFiles(files, key, isSingle) {
        const uploaded = await api(auth.user.token).files.upload(files);
        console.log(uploaded);

        const newFormData = { ...formData };
        if (isSingle) {
          newFormData[key] = uploaded.files[0].location;
        } else {
          if (!Array.isArray(newFormData[key])) {
            newFormData[key] = [];
          }
          newFormData[key].push(
            ...uploaded.files.map(({ location }) => {
              return location;
            })
          );
        }
        setFormData(newFormData);
      }

      function handleSelectChange(value) {
        const newFormData = { ...formData };
        newFormData[this.name] = value;
        setFormData(newFormData);
      }

      function handleCloseTag(formKey, removedTag) {
        // const tags = this.state.tags.filter(tag => tag !== removedTag);
        // console.log(tags);
        // this.setState({ tags });
        try {
          const newFormData = { ...formData };

          newFormData[formKey] = newFormData[formKey].filter(
            tag => tag !== removedTag
          );
          setFormData(newFormData);
        } catch (error) {
          console.log(error);
        }
      }

      function handleAddTag(formKey, addedTag) {
        try {
          const newFormData = { ...formData };

          newFormData[formKey].push(addedTag);
          setFormData(newFormData);
        } catch (error) {
          console.log(error);
        }
      }

      return (
        <>
          <Row gutter={[16, 16]} align="middle">
            <Col>
              <Title level={4}>배너 관리</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={20}>
              <Text>
                {formData.name}({formData._id})
              </Text>
            </Col>
            <Col xs={24} sm={4} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  Modal.info({
                    title: "JSON",
                    content: <pre>{JSON.stringify(formData, null, 2)}</pre>,
                    onOk() {}
                  });
                }}
                icon="database"
              >
                JSON 보기...
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <br />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 4 },
                  md: { span: 2 },
                  style: { textAlign: "left" }
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 20 },
                  md: { span: 22 }
                }}
              >
                <Form.Item label="배너 제목">
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    style={{ maxWidth: 400 }}
                  />
                </Form.Item>
                {!createMode && (
                  <>
                    <Form.Item label="생성일">
                      <Text>
                        {new Date(formData.created_at).toLocaleString()}
                      </Text>
                    </Form.Item>
                    <Form.Item label="최종 수정일">
                      <Text>
                        {new Date(formData.updated_at).toLocaleString()}
                      </Text>
                    </Form.Item>
                  </>
                )}
                <Form.Item label="이미지">
                  {/* <Input
                    name="image"
                    value={formData.image}
                    onChange={handleChange}
                    style={{ maxWidth: 800 }}
                  />
                  <br /> */}

                  {formData.image && (
                    <>
                      <img
                        src={formData.image}
                        alt="banner"
                        style={{
                          width: "100%",
                          maxWidth: 320,
                          maxHeight: 320,
                          objectFit: "contain",
                          objectPosition: "0 0"
                        }}
                      />
                      &nbsp;
                      <Button
                        icon="minus"
                        type="danger"
                        onClick={() => {
                          setStateKeyValue("image", "");
                        }}
                        style={{ verticalAlign: "top" }}
                      />
                      <br />
                    </>
                  )}
                  <Upload
                    name="image"
                    // listType="picture-card"
                    showUploadList={false}
                    // action={
                    //   process.env.REACT_APP_API_URL + "/api/v1/files/upload"
                    // }
                    // headers={{ "x-access-token": auth.user.token }}
                    // onChange={console.log}
                    // onChange={handleFileChange}
                    beforeUpload={file => {
                      uploadFiles(file, "image", true);
                      return false;
                    }}
                  >
                    <Button>
                      <Icon type="plus" /> 클릭하여 업로드
                    </Button>
                  </Upload>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col style={{ textAlign: "right" }}>
              <Button type="primary" icon="save" onClick={saveData}>
                저장
              </Button>
            </Col>
          </Row>
        </>
      );
    })
  )
);
