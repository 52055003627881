import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  Form, //
  Input,
  Button,
  Row,
  Col,
  Select,
  Typography,
  Modal,
  DatePicker,
  message
} from "antd";
import moment from "moment";

import api from "utils/api";

const comma = require("comma-number");

const { Text, Title } = Typography;

const videoTypes = [
  "자기이해",
  "진로탐색",
  "선택과 확신",
  "실행과 달성",
  "진로 재점검",
  "인생계획"
];
const dateFormat = "YYYY-MM-DD";

export default inject("auth")(
  inject("routing")(
    observer(({ match, auth, routing }) => {
      const [formData, setFormData] = useState({});
      const [createMode, setCreateMode] = useState(false);

      async function fetchData(bid) {
        const result = await api(auth.user.token).books.findOne({ bid });

        if (result.book) {
          setFormData(result.book);
        }
      }

      async function saveData() {
        let result = {};

        if (createMode) {
          result = await api(auth.user.token).books.create(formData);
        } else {
          result = await api(auth.user.token).books.update({
            ...formData,
            bid: match.params.bid
          });
        }
        console.log(result);
        if (result.book) {
          message.success("저장되었습니다.");
          routing.push("/books");
        } else {
          message.error(JSON.stringify(result, null, 2));
        }
      }

      useEffect(() => {
        if (match.params.bid === "new") {
          setCreateMode(true);
        } else {
          fetchData(match.params.bid);
        }
      }, []);

      function handleChange(e) {
        const { value, name } = e.target;
        const newFormData = { ...formData };
        newFormData[name] = value;
        setFormData(newFormData);
      }

      function handleSelectChange(value) {
        const newFormData = { ...formData };
        newFormData[this.name] = value;
        setFormData(newFormData);
      }

      return (
        <>
          <Row gutter={[16, 16]} align="middle">
            <Col>
              <Title level={4}>북코드 관리</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={20}>
              <Text>
                {formData.code}({formData._id})
              </Text>
            </Col>
            <Col xs={24} sm={4} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  Modal.info({
                    title: "JSON",
                    content: <pre>{JSON.stringify(formData, null, 2)}</pre>,
                    onOk() {}
                  });
                }}
                icon="database"
              >
                JSON 보기...
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <br />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 4 },
                  md: { span: 2 },
                  style: { textAlign: "left" }
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 20 },
                  md: { span: 22 }
                }}
              >
                <Form.Item label="유형 구분">
                  <Select
                    name="type"
                    style={{ width: "100%", maxWidth: 200 }}
                    value={formData.type}
                    onChange={handleSelectChange}
                  >
                    {videoTypes.map((value, index) => {
                      return (
                        <Select.Option value={index}>{value}</Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="코드">
                  <Input
                    name="code"
                    value={formData.code}
                    onChange={handleChange}
                    style={{ maxWidth: 400 }}
                  />
                </Form.Item>
                <Form.Item label="만료일">
                  <DatePicker
                    value={moment(formData.expiry, dateFormat).utc(true)}
                    format={dateFormat}
                    onChange={date => {
                      try {
                        const newFormData = { ...formData };
                        newFormData.expiry = date.toJSON();
                        setFormData(newFormData);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    allowClear={false}
                  />
                </Form.Item>
                {!createMode && (
                  <>
                    <Form.Item label="생성일">
                      <Text>{new Date(formData.created_at).toLocaleString()}</Text>
                    </Form.Item>
                    <Form.Item label="최종 수정일">
                      <Text>{new Date(formData.updated_at).toLocaleString()}</Text>
                    </Form.Item>
                    <Form.Item label="누적 인증 수">
                      <Text>{comma(formData.hits)}</Text>
                    </Form.Item>
                  </>
                )}
              </Form>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col style={{ textAlign: "right" }}>
              <Button type="primary" icon="save" onClick={saveData}>
                저장
              </Button>
            </Col>
          </Row>
        </>
      );
    })
  )
);
