import { action, observable, set } from "mobx";
import autoSave from "utils/storeAutoSave";
import api from "utils/api";

export class AuthStore {
  @observable user = {
    token: undefined,
    name: ""
  };

  @action async signin(email, password) {
    let isFailed = false;
    try {
      const signinResult = await api().auth.signin({ email, password });

      if (signinResult && signinResult.token) {
        const token = signinResult.token;
        const userResult = await api(token).users.me();
        if (userResult && userResult.user) {
          const { user } = userResult;
          if (user.userType !== "admin") {
            isFailed = true;
          } else {
            this.user = user;
            this.user.token = token;
          }
        } else {
          isFailed = true;
        }
      } else {
        isFailed = true;
      }
    } catch (error) {
      isFailed = true;
    }

    if (isFailed) {
      this.user = {};
      return false;
    }
    return this.user;
  }

  @action async me() {
    try {
      const token = this.user.token;
      const result = await api(token).users.me();
      this.user = result.user;
      this.user.token = token;
      return this.user;
    } catch (error) {
      return {};
    }
  }

  @action signout() {
    this.user = {};
    return true;
  }

  constructor() {
    this.load();
    autoSave(this, this.save.bind(this));
  }

  load() {
    if (localStorage.getItem("user") !== null) {
      const data = localStorage.getItem("user");
      set(this, JSON.parse(data));
      console.log(this.user);
      console.log("loaded");
    }
  }

  save(json) {
    localStorage.setItem("user", json);
  }
}

export default AuthStore;
