import React from "react";
import { inject, observer } from "mobx-react";
import { Form, Icon, Input, Button, Layout, message } from "antd";

import api from "utils/api";

const { Content } = Layout;

const LoginForm = inject("auth")(
  observer(({ form, auth }) => {
    const handleSubmit = e => {
      e.preventDefault();
      form.validateFields(async (err, values) => {
        if (!err) {
          // console.log("Received values of form: ", values);
          const { email, password } = values;
          const result = await auth.signin(email, password);
          if (!result) {
            message.error("로그인에 실패하였습니다.");
          }
        }
      });
    };

    const { getFieldDecorator } = form;

    return (
      <>
        <Form onSubmit={handleSubmit} className="login-form">
          <Form.Item label="Email">
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "올바른 이메일 형식으로 입력해주세요."
                },
                { required: true, message: "이메일을 입력해주세요." }
              ]
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="admin@example.com"
              />
            )}
          </Form.Item>
          <Form.Item label="Password">
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "비밀번호를 입력해주세요." }]
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="●●●●●●●●"
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  })
);

const WrappedLoginForm = Form.create({ name: "login" })(LoginForm);

export default () => {
  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Content
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div
            style={{
              width: "100%",
              maxWidth: 360,
              margin: "0 auto",
              padding: 24,
              backgroundColor: "#ffffff"
            }}
          >
            <WrappedLoginForm />
          </div>
        </Content>
      </Layout>
    </>
  );
};
