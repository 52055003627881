import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  Form, //
  Input,
  Button,
  Row,
  Col,
  Select,
  Typography,
  DatePicker,
  Modal,
  Upload,
  Icon,
  Table,
  message
} from "antd";
import moment from "moment";
import "moment/locale/ko";

import api from "utils/api";

const { Text, Title } = Typography;
const dateFormat = "YYYY-MM-DD";

const commentsColumns = [
  {
    title: "비디오",
    dataIndex: "vcode",
    key: "vcode"
  },
  {
    title: "내용",
    dataIndex: "content",
    key: "content",
    render: (value, comment) => (
      <Link to={`/comments/${comment._id}`}>{value}</Link>
    )
  },
  {
    title: "생성일",
    dataIndex: "created_at",
    key: "created_at",
    render: value => {
      return new Date(value).toLocaleString();
    }
  },
  {
    title: "유형",
    dataIndex: "isSecret",
    key: "isSecret",
    render: value => {
      return value ? "비밀" : "공개";
    }
  },
  {
    title: "PK",
    dataIndex: "_id",
    key: "_id"
  }
];
export default inject("auth")(
  inject("routing")(
    observer(({ match, auth, routing }) => {
      const [formData, setFormData] = useState({});
      const [changePasswordMode, setChangePasswordMode] = useState(false);
      const [createMode, setCreateMode] = useState(false);

      async function fetchData(uid) {
        const result = await api(auth.user.token).users.findOne({ uid });

        if (result.user) {
          setFormData(result.user);
        }
      }

      async function saveData() {
        let result = {};

        if (createMode) {
          result = await api(auth.user.token).users.create(formData);
        } else {
          result = await api(auth.user.token).users.update({
            ...formData,
            uid: match.params.uid
          });
        }
        console.log(result);
        if (result.user || result.token) {
          message.success("저장되었습니다.");
          routing.push("/users");
        } else {
          message.error(JSON.stringify(result, null, 2));
        }
      }

      useEffect(() => {
        if (match.params.uid === "new") {
          setChangePasswordMode(true);
          setCreateMode(true);
        } else {
          fetchData(match.params.uid);
        }
      }, []);

      function handleChange(e) {
        const { value, name } = e.target;
        const newFormData = { ...formData };
        const splitedName = name.split(".");
        if (splitedName.length <= 1) {
          newFormData[name] = value;
        } else {
          let obj = newFormData;
          splitedName.forEach((key, index) => {
            if (!obj[key]) {
              obj[key] = {};
            }
            if (index >= splitedName.length - 1) {
              obj[key] = value;
            }
            obj = obj[key];
          });
        }
        setFormData(newFormData);
      }

      function handleSelectChange(value) {
        const newFormData = { ...formData };
        newFormData[this.name] = value;
        setFormData(newFormData);
      }

      function setStateKeyValue(key, value) {
        const newFormData = { ...formData };
        newFormData[key] = value;
        setFormData(newFormData);
      }

      async function uploadFiles(files, key, isSingle) {
        const uploaded = await api(auth.user.token).files.upload(files);
        console.log(uploaded);

        const newFormData = { ...formData };
        if (isSingle) {
          newFormData[key] = uploaded.files[0].location;
        } else {
          if (!Array.isArray(newFormData[key])) {
            newFormData[key] = [];
          }
          newFormData[key].push(
            ...uploaded.files.map(({ location }) => {
              return location;
            })
          );
        }
        setFormData(newFormData);
      }

      return (
        <>
          <Row gutter={[16, 16]} align="middle">
            <Col>
              <Title level={4}>회원 정보</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={20}>
              <Text>
                {formData.email}({formData._id})
              </Text>
            </Col>
            <Col xs={24} sm={4} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  Modal.info({
                    title: "JSON",
                    content: <pre>{JSON.stringify(formData, null, 2)}</pre>,
                    onOk() {}
                  });
                }}
                icon="database"
              >
                JSON 보기...
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <br />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 4 },
                  md: { span: 2 },
                  style: { textAlign: "left" }
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 20 },
                  md: { span: 22 }
                }}
              >
                <Form.Item label="이메일">
                  <Input
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    style={{ maxWidth: 200 }}
                  />
                </Form.Item>
                <Form.Item label="이름">
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    style={{ maxWidth: 200 }}
                  />
                </Form.Item>

                <Form.Item label="비밀번호">
                  {!changePasswordMode && !createMode ? (
                    <>
                      <Text>{formData.passwordHash}</Text>
                      &nbsp;
                      <Button
                        type="primary"
                        onClick={() => {
                          setChangePasswordMode(true);
                        }}
                        icon="edit"
                      >
                        비밀번호 변경
                      </Button>
                    </>
                  ) : (
                    <>
                      <Input
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        style={{ maxWidth: 200 }}
                      />
                      {!createMode && (
                        <Button
                          type="link"
                          onClick={() => {
                            setChangePasswordMode(false);
                            const newFormData = { ...formData };
                            delete newFormData.password;
                            setFormData(newFormData);
                          }}
                          icon="close"
                        >
                          변경 취소
                        </Button>
                      )}
                    </>
                  )}
                </Form.Item>
                <Form.Item label="회원 유형">
                  <Select
                    name="userType"
                    value={formData.userType}
                    onChange={handleSelectChange}
                    style={{ maxWidth: 100 }}
                  >
                    <Select.Option value={"user"}>일반 회원</Select.Option>
                    <Select.Option value={"admin"}>관리자</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="인증 여부">
                  <Select
                    name="isConfirmed"
                    value={formData.isConfirmed}
                    onChange={handleSelectChange}
                    style={{ maxWidth: 100 }}
                  >
                    <Select.Option value={false}>아니오</Select.Option>
                    {/* eslint-disable-next-line react/jsx-boolean-value */}
                    <Select.Option value={true}>예(이메일)</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="탈퇴여부">
                  <Select
                    name="isDropped"
                    value={formData.isDropped}
                    onChange={handleSelectChange}
                    style={{ maxWidth: 100 }}
                  >
                    <Select.Option value={false}>아니오</Select.Option>
                    {/* eslint-disable-next-line react/jsx-boolean-value */}
                    <Select.Option value={true}>예</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="이메일">
                  <Input
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    style={{ maxWidth: 200 }}
                  />
                  &nbsp;
                  <Text>
                    이메일 수신 {formData.marketing_email ? "동의" : "미동의"}
                  </Text>
                </Form.Item>
                <Form.Item label="전화번호">
                  <Input
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    style={{ maxWidth: 200 }}
                  />
                  &nbsp;
                  <Text>
                    문자 수신 {formData.marketing_sms ? "동의" : "미동의"}
                  </Text>
                </Form.Item>
                <Form.Item label="생년월일">
                  <DatePicker
                    value={moment(formData.birthday, dateFormat).utc(true)}
                    format={dateFormat}
                    onChange={date => {
                      try {
                        console.log(date);
                        console.log(date.toJSON());
                        const newFormData = { ...formData };
                        newFormData.birthday = date.toJSON();
                        setFormData(newFormData);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    allowClear={false}
                  />
                </Form.Item>
                <Form.Item label="성별">
                  <Select
                    name="gender"
                    value={formData.gender}
                    onChange={handleSelectChange}
                    style={{ maxWidth: 100 }}
                  >
                    <Select.Option value="male">남성</Select.Option>
                    <Select.Option value="female">여성</Select.Option>
                    <Select.Option value="other">그 외</Select.Option>
                    <Select.Option value="unknown">알 수 없음</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="이미지 URL">
                  {/* <img
                    src={formData.profileImage}
                    alt="profile"
                    style={{ maxWidth: 200 }}
                  />
                  <br />
                  <Input
                    name="profileImage"
                    value={formData.profileImage}
                    onChange={handleChange}
                    style={{ maxWidth: 600 }}
                  /> */}

                  {formData.profileImage && (
                    <>
                      <img
                        src={formData.profileImage}
                        alt="banner"
                        style={{
                          width: "100%",
                          maxWidth: 480,
                          maxHeight: 480,
                          objectFit: "contain",
                          objectPosition: "0 0"
                        }}
                      />
                      &nbsp;
                      <Button
                        icon="minus"
                        type="danger"
                        onClick={() => {
                          setStateKeyValue("profileImage", "");
                        }}
                        style={{ verticalAlign: "top" }}
                      />
                      <br />
                    </>
                  )}
                  <Upload
                    name="profileImage"
                    // listType="picture-card"
                    showUploadList={false}
                    // action={
                    //   process.env.REACT_APP_API_URL + "/api/v1/files/upload"
                    // }
                    // headers={{ "x-access-token": auth.user.token }}
                    // onChange={console.log}
                    // onChange={handleFileChange}
                    beforeUpload={file => {
                      uploadFiles(file, "profileImage", true);
                      return false;
                    }}
                  >
                    <Button>
                      <Icon type="plus" /> 클릭하여 업로드
                    </Button>
                  </Upload>
                </Form.Item>
                {!createMode && (
                  <>
                    <Form.Item label="회원 가입일">
                      <Text>
                        {new Date(formData.created_at).toLocaleString()}
                      </Text>
                    </Form.Item>
                    <Form.Item label="최근 로그인">
                      <Text>
                        {new Date(formData.last_signin).toLocaleString()}
                      </Text>
                    </Form.Item>
                    <Form.Item label="최종 수정일">
                      <Text>
                        {new Date(formData.updated_at).toLocaleString()}
                      </Text>
                    </Form.Item>
                  </>
                )}
              </Form>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col style={{ textAlign: "right" }}>
              <Button type="primary" icon="save" onClick={saveData}>
                저장
              </Button>
            </Col>
          </Row>
          {!createMode && (
            <>
              <Row gutter={[16, 16]}>
                <Col>
                  <Text strong>댓글 내역</Text>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Table
                    dataSource={formData.comments}
                    columns={commentsColumns}
                  />
                </Col>
              </Row>
            </>
          )}
          {formData.isDropped && (
            <>
              <br />
              <br />
              <Row gutter={[16, 16]}>
                <Col>
                  <Text strong>탈퇴 사유</Text>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col>
                  <Form>
                    <Form.Item label="탈퇴 시간">
                      {new Date(formData.drop_date).toLocaleString()}
                    </Form.Item>
                    <Form.Item label="필수항목">
                      {formData.dropReason &&
                        formData.dropReason.checked &&
                        Array.isArray(formData.dropReason.checked) &&
                        formData.dropReason.checked.join(", ")}
                    </Form.Item>
                    <Form.Item label="선택항목">
                      {formData.dropReason && formData.dropReason.text}
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </>
          )}
        </>
      );
    })
  )
);
