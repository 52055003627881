import React from "react";
import { Layout, Menu, Breadcrumb, Icon, Row, Col, Button } from "antd";

import { inject, observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { Switch, Route, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import Sider from "components/Sider";
import Signin from "pages/Signin";
import Users from "pages/Users";
import Questions from "pages/Questions";
import Forms from "pages/Forms";
import Results from "pages/Results";
import Products from "pages/Products";
import Banners from "pages/Banners";
import Videos from "pages/Videos";
import Comments from "pages/Comments";
import Books from "pages/Books";
import Boards from "pages/Boards";
import Articles from "pages/Articles";
import Reports from "pages/Reports";

import "antd/dist/antd.css";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

const { Header, Content } = Layout;

const App = inject("auth")(
  inject("routing")(
    observer(({ auth, routing }) => {
      return (
        <div className="App">
          <Helmet>
            <meta charSet="utf-8" />
            <title>pg-admin-frontend</title>
          </Helmet>
          <ToastContainer position={toast.POSITION.TOP_CENTER} />
          {auth.user.token ? (
            <Layout style={{ height: "100vh" }}>
              <Header className="header" style={{ color: "#ffffff" }}>
                <Row>
                  <Col span={6}>
                    <div className="logo">프로진로고민러 관리자</div>
                  </Col>
                  <Col span={18} style={{ textAlign: "right" }}>
                    {/* <Menu
                      theme="dark"
                      mode="horizontal"
                      defaultSelectedKeys={["2"]}
                      style={{ lineHeight: "64px" }}
                    >
                      <Menu.Item key="1">nav 1</Menu.Item>
                      <Menu.Item key="2">nav 2</Menu.Item>
                      <Menu.Item key="3">로그아웃</Menu.Item>
                    </Menu> */}
                    {auth.user.name}님 &nbsp;
                    <Button
                      type="danger"
                      icon="poweroff"
                      onClick={() => {
                        auth.signout();
                      }}
                    >
                      로그아웃
                    </Button>
                  </Col>
                </Row>
              </Header>
              <Layout>
                <Sider />
                <Layout style={{ padding: "0 24px 24px" }}>
                  <Breadcrumb style={{ margin: "16px 0" }}>
                    {routing.location.pathname.split("/").map(item => {
                      return <Breadcrumb.Item>{item}</Breadcrumb.Item>;
                    })}
                  </Breadcrumb>
                  <Content
                    style={{
                      background: "#fff",
                      padding: 24,
                      margin: 0,
                      minHeight: 280,
                      overflow: "auto"
                    }}
                  >
                    <Switch>
                      <Route path="/users" component={Users} />
                      <Route path="/test/questions" component={Questions} />
                      <Route path="/test/forms" component={Forms} />
                      <Route path="/test/results" component={Results} />
                      <Route path="/products" component={Products} />
                      <Route path="/banners" component={Banners} />
                      <Route path="/videos" component={Videos} />
                      <Route path="/comments" component={Comments} />
                      <Route path="/books" component={Books} />
                      <Route path="/boards/:bid/articles" component={Articles} />
                      <Route path="/boards" component={Boards} />
                      <Route path="/reports" component={Reports} />
                    </Switch>
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          ) : (
            <Signin />
          )}
        </div>
      );
    })
  )
);

export default App;
