import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  Form, //
  Input,
  Button,
  Row,
  Col,
  Select,
  Typography,
  Modal,
  message,
  Tag,
  InputNumber,
  Table
} from "antd";

import api from "utils/api";

const comma = require("comma-number");

const { Text, Title } = Typography;

const videoTypes = [
  "자기이해",
  "진로탐색",
  "선택과 확신",
  "실행과 달성",
  "진로 재점검",
  "인생계획"
];

const commentCodes = [];

for (let i = 1; i <= 6; i++) {
  for (let j = 1; j <= 6; j++) {
    commentCodes.push(`comment_${("0" + i).slice(-2)}_${("0" + j).slice(-2)}`);
  }
}

export default inject("auth")(
  inject("routing")(
    observer(({ match, auth, routing }) => {
      const [formData, setFormData] = useState({});
      const [createMode, setCreateMode] = useState(false);

      async function fetchData(cid) {
        const result = await api(auth.user.token).comments.findOne({ cid });

        if (result.comment) {
          setFormData(result.comment);
        }
      }

      async function saveData() {
        let result = {};

        if (createMode) {
          result = await api(auth.user.token).comments.create(formData);
        } else {
          result = await api(auth.user.token).comments.update({
            ...formData,
            cid: match.params.cid
          });
        }
        console.log(result);
        if (result.comment) {
          message.success("저장되었습니다.");
          routing.push("/comments");
        } else {
          message.error(JSON.stringify(result, null, 2));
        }
      }

      useEffect(() => {
        if (match.params.cid === "new") {
          setCreateMode(true);
        } else {
          fetchData(match.params.cid);
        }
      }, []);

      function handleChange(e) {
        const { value, name } = e.target;
        const newFormData = { ...formData };
        newFormData[name] = value;
        setFormData(newFormData);
      }

      function handleSelectChange(value) {
        const newFormData = { ...formData };
        newFormData[this.name] = value;
        setFormData(newFormData);
      }

      function setStateKeyValue(key, value) {
        const newFormData = { ...formData };
        newFormData[key] = value;
        setFormData(newFormData);
      }

      function handleCloseTag(formKey, removedTag) {
        // const tags = this.state.tags.filter(tag => tag !== removedTag);
        // console.log(tags);
        // this.setState({ tags });
        try {
          const newFormData = { ...formData };

          newFormData[formKey] = newFormData[formKey].filter(
            tag => tag !== removedTag
          );
          setFormData(newFormData);
        } catch (error) {
          console.log(error);
        }
      }

      function handleAddTag(formKey, addedTag) {
        try {
          const newFormData = { ...formData };
          if (!Array.isArray(newFormData[formKey])) {
            newFormData[formKey] = [];
          }
          newFormData[formKey].push(addedTag);
          setFormData(newFormData);
        } catch (error) {
          console.log(error);
        }
      }

      const columns = [
        {
          title: "회원 이메일",
          dataIndex: "user",
          key: "user",
          render: user => {
            return user && user.email;
          }
        },
        {
          title: "내용",
          dataIndex: "content",
          key: "content"
        },
        {
          title: "생성일",
          dataIndex: "created_at",
          key: "created_at",
          render: value => {
            return new Date(value).toLocaleString();
          }
        },
        {
          title: "유형",
          dataIndex: "isSecret",
          key: "isSecret",
          render: value => {
            return value ? "비밀" : "공개";
          }
        },
        {
          title: "PK",
          dataIndex: "_id",
          key: "_id"
        }
      ];

      function toString(obj) {
        let result = "";
        if (typeof obj === "string") {
          result = obj;
        } else if (!obj) {
          result = "";
        } else {
          result = "" + obj;
        }
        return result;
      }

      return (
        <>
          <Row gutter={[16, 16]} align="middle">
            <Col>
              <Title level={4}>댓글 관리</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={20}>
              <Text>({formData._id})</Text>
            </Col>
            <Col xs={24} sm={4} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  Modal.info({
                    title: "JSON",
                    content: <pre>{JSON.stringify(formData, null, 2)}</pre>,
                    onOk() {}
                  });
                }}
                icon="database"
              >
                JSON 보기...
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <br />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 6 },
                  md: { span: 4 },
                  style: { textAlign: "left" }
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 18 },
                  md: { span: 20 }
                }}
              >
                <Form.Item label="영상 코드">
                  <Text>{formData.vcode}</Text>
                </Form.Item>
                <Form.Item label="회원 이메일">
                  <Text>{formData.user && formData.user.email}</Text>
                </Form.Item>
                <Form.Item label="공개 여부">
                  <Text>{formData.isSecret ? "비밀" : "공개"}</Text>
                </Form.Item>

                {!createMode && (
                  <>
                    <Form.Item label="생성일">
                      <Text>
                        {new Date(formData.created_at).toLocaleString()}
                      </Text>
                    </Form.Item>
                    <Form.Item label="최종 수정일">
                      <Text>
                        {new Date(formData.updated_at).toLocaleString()}
                      </Text>
                    </Form.Item>
                  </>
                )}

                <Form.Item label="답글 수">
                  <Text>
                    {formData.replies &&
                      Array.isArray(formData.replies) &&
                      formData.replies.length}
                  </Text>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {/* <Row gutter={[16, 16]}>
            <Col style={{ textAlign: "right" }}>
              <Button type="primary" icon="save" onClick={saveData}>
                저장
              </Button>
            </Col>
          </Row> */}
          {!createMode && (
            <>
              <Row gutter={[16, 16]}>
                <Col>
                  <Text strong>답글 내역</Text>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Table
                    dataSource={formData.replies}
                    columns={columns}
                    // rowSelection={rowSelection}
                    // pagination={pagination}
                    // onChange={handleTableChange}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24} style={{ textAlign: "right" }}>
                  <a
                    href={`https://progominler.com/lectures/videos/${formData.vcode}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button type="primary">답글 달기</Button>
                  </a>
                </Col>
              </Row>
            </>
          )}
        </>
      );
    })
  )
);
