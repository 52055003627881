import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Link, Route } from "react-router-dom";
import {
  Typography,
  Row,
  Col,
  Input,
  Button,
  Select,
  Table,
  Popconfirm,
  message
} from "antd";

import api from "utils/api";
import Question from "./Question";
import Type from "./Type";
import Types from "./Types";
import Users from "./Users";

const comma = require("comma-number");

export default ({ match }) => (
  <>
    <Route path={`${match.path}/users`} component={Users} />
    <Route path={`${match.path}/type`} component={Type} />
    <Route path={`${match.path}/question`} component={Question} />
    <Route path={`${match.path}/types`} component={Types} />
  </>
);
