import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Link, Route } from "react-router-dom";
import {
  Typography,
  Row,
  Col,
  Input,
  Button,
  Select,
  Table,
  Popconfirm,
  Tabs,
  Card,
  Icon,
  message
} from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import api from "utils/api";
// import Question from "./Question";

const comma = require("comma-number");

const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const questionTypes = [
  "자기이해",
  "진로탐색",
  "선택과 확신",
  "실행과 달성",
  "진로 재점검",
  "인생계획"
];
export default inject("auth")(
  inject("routing")(
    observer(({ auth, match, routing }) => {
      const [loading, setLoading] = useState(false);
      const [data, setData] = useState([]);
      const [selectedType, setSelectedType] = useState(0);
      const [selectedData, setSelectedData] = useState({});
      async function fetchQuestionList(type = 0) {
        setLoading(true);
        try {
          const questions = await api(auth.user.token).questions.list({
            page: 1,
            limit: 10000,
            type
          });

          const forms = await api(auth.user.token).forms.list({ type });

          console.log(questions.questions.docs);
          console.log(forms);

          if (forms.forms.length <= 0) {
            const form = await api(auth.user.token).forms.create({
              type,
              questions: []
            });
            setSelectedData(form);
          } else {
            questions.questions.docs = questions.questions.docs.filter(item => {
              let result = true;
              forms.forms[0].questions.forEach(item2 => {
                if (item2._id === item._id) {
                  result = false;
                }
              });
              return result;
            });
            setSelectedData(forms.forms[0]);
          }

          setData(questions.questions.docs);
        } catch (error) {
          message.error(JSON.stringify(error, null, 4));
        } finally {
          setLoading(false);
        }
      }

      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      };

      const move = (
        source,
        destination,
        droppableSource,
        droppableDestination
      ) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
      };

      const getList = listId => {
        let result = [];
        if (listId === "questions") {
          result = data;
        } else if (listId === "selected") {
          result = selectedData.questions;
        }

        return result;
      };

      const onDragEnd = result => {
        const { source, destination } = result;

        console.log(source);
        console.log(destination);

        // dropped outside the list
        if (!destination) {
          return;
        }

        if (source.droppableId === destination.droppableId) {
          const items = reorder(
            getList(source.droppableId),
            source.index,
            destination.index
          );

          // let state = { items };
          console.log(getList(source.droppableId));

          if (source.droppableId === "questions") {
            setData(items);
          }
          if (source.droppableId === "selected") {
            const newSelectedData = { ...selectedData };
            newSelectedData.questions = items;
            setSelectedData(newSelectedData);
          }
        } else {
          const moved = move(
            getList(source.droppableId),
            getList(destination.droppableId),
            source,
            destination
          );
          console.log(getList(source.droppableId));
          console.log(getList(destination.droppableId));
          console.log(moved);

          if (moved.selected.length > 6) {
            message.error(
              "질문 그룹은 6개까지의 질문으로만 구성할 수 있습니다."
            );
          } else {
            setData(moved.questions);
            const newSelectedData = { ...selectedData };
            newSelectedData.questions = moved.selected;
            setSelectedData(newSelectedData);
          }
        }
      };
      const grid = 8;

      const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        // padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "#fafafa" : "#f0f0f0",

        // styles we need to apply on draggables
        ...draggableStyle
      });

      const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "#f0f0f0" : "#f0f0f0",
        maxWidth: 480,
        minHeight: 240,
        padding: grid
      });

      useEffect(() => {
        fetchQuestionList(selectedType);
      }, []);

      async function saveData() {
        // let result = {};

        // if (createMode) {
        //   result = await api(auth.user.token).results.create(formData);
        // } else {
        //   result = await api(auth.user.token).results.update({
        //     ...formData,
        //     rid: match.params.rid
        //   });
        // }

        const result = await api(auth.user.token).forms.update({
          fid: selectedData._id,
          ...selectedData
        });

        console.log(result);
        if (result.form) {
          message.success("저장되었습니다.");
          // routing.push("/test/results");
        } else {
          message.error(JSON.stringify(result, null, 4));
        }
      }

      // function handleTableChange(pager, filters, sorter) {
      //   fetchQuestionList(pager.current, pager.pageSize);
      // }

      return (
        <>
          <Route path={`${match.path}`} exact>
            <Row gutter={[16, 16]}>
              <Col>
                <Title level={4}>유형 테스트 질문 그룹 구성</Title>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={20}>
                <Tabs
                  defaultActiveKey="0"
                  onChange={activeKey => {
                    const type = parseInt(activeKey, 10);
                    fetchQuestionList(type);
                    setSelectedType(type);
                  }}
                >
                  {questionTypes.map((value, index) => {
                    const key = index + "";
                    return <TabPane tab={value} key={key} />;
                  })}
                </Tabs>
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                <Button type="primary" icon="save" onClick={saveData}>
                  저장
                </Button>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Col span={12}>
                  <Text strong>
                    그룹 구성된 질문 목록
                    <br />
                    <br />
                  </Text>
                  <Droppable droppableId="selected">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {selectedData.questions &&
                          selectedData.questions.map((item, index) => (
                            <Draggable
                              key={item._id}
                              draggableId={item._id}
                              index={index}
                            >
                              {(provided2, snapshot2) => (
                                <div
                                  ref={provided2.innerRef}
                                  {...provided2.draggableProps}
                                  {...provided2.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot2.isDragging,
                                    provided2.draggableProps.style
                                  )}
                                >
                                  <Card size="small" title={item.title}>
                                    {item.content}
                                  </Card>
                                </div>
                              )}
                            </Draggable>
                          ))}
                      </div>
                    )}
                  </Droppable>
                </Col>
                <Col span={12}>
                  <Text strong>
                    사용 대기중인 질문 목록
                    <br />
                    <br />
                  </Text>
                  <Droppable droppableId="questions">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {data &&
                          data.map((item, index) => (
                            <Draggable
                              key={item._id}
                              draggableId={item._id}
                              index={index}
                            >
                              {(provided2, snapshot2) => (
                                <div
                                  ref={provided2.innerRef}
                                  {...provided2.draggableProps}
                                  {...provided2.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot2.isDragging,
                                    provided2.draggableProps.style
                                  )}
                                >
                                  <Card size="small" title={item.title}>
                                    {item.content}
                                  </Card>
                                </div>
                              )}
                            </Draggable>
                          ))}
                      </div>
                    )}
                  </Droppable>
                </Col>
              </DragDropContext>
            </Row>
          </Route>
          {loading && (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "fixed",
                left: 0,
                top: 0,
                opacity: 0.5,
                backgroundColor: "#777777",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 300
              }}
            >
              <Icon
                type="loading"
                style={{ fontSize: 48, color: "lightblue" }}
              />
            </div>
          )}
          {/* <Route path={`${match.path}/:rid`} component={Question} /> */}
        </>
      );
    })
  )
);
