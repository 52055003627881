import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  Form, //
  Input,
  Button,
  Row,
  Col,
  Select,
  Typography,
  Modal,
  message,
  Tag,
  InputNumber,
  Upload,
  Icon
} from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import api from "utils/api";

const { Text, Title } = Typography;
const productTypes = ["진로 코칭 플래너", "노트/메모지", "카드/엽서"];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? "#ffffff" : "#ffffff",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#ffffff" : "#ffffff",
  display: "flex",
  padding: grid,
  overflow: "auto"
});

export default inject("auth")(
  inject("routing")(
    observer(({ match, auth, routing }) => {
      const [formData, setFormData] = useState({});
      const [createMode, setCreateMode] = useState(false);
      const [newTagValue, setNewTagValue] = useState("");

      async function fetchData(rid) {
        const result = await api(auth.user.token).products.findOne({ rid });

        if (result.product) {
          setFormData(result.product);
        }
      }

      async function saveData() {
        let result = {};

        if (createMode) {
          result = await api(auth.user.token).products.create(formData);
        } else {
          result = await api(auth.user.token).products.update({
            ...formData,
            rid: match.params.rid
          });
        }
        console.log(result);
        if (result.product) {
          message.success("저장되었습니다.");
          routing.push("/products");
        } else {
          message.error(JSON.stringify(result, null, 2));
        }
      }

      useEffect(() => {
        if (match.params.rid === "new") {
          setCreateMode(true);
        } else {
          fetchData(match.params.rid);
        }
      }, []);

      function handleChange(e) {
        const { value, name } = e.target;
        const newFormData = { ...formData };
        newFormData[name] = value;
        setFormData(newFormData);
      }

      function handleSelectChange(value) {
        const newFormData = { ...formData };
        newFormData[this.name] = value;
        setFormData(newFormData);
      }

      function setStateKeyValue(key, value) {
        const newFormData = { ...formData };
        newFormData[key] = value;
        setFormData(newFormData);
      }

      async function uploadFiles(files, key, isSingle) {
        const uploaded = await api(auth.user.token).files.upload(files);
        console.log(uploaded);

        const newFormData = { ...formData };
        if (isSingle) {
          newFormData[key] = uploaded.files[0].location;
        } else {
          if (!Array.isArray(newFormData[key])) {
            newFormData[key] = [];
          }
          newFormData[key].push(
            ...uploaded.files.map(({ location }) => {
              return location;
            })
          );
        }
        setFormData(newFormData);
      }

      function handleCloseTag(formKey, removedTag) {
        try {
          const newFormData = { ...formData };
          console.log(newFormData[formKey]);
          newFormData[formKey] = newFormData[formKey].filter(tag => {
            console.log(tag);
            console.log(removedTag);
            console.log(tag !== removedTag);
            return tag !== removedTag;
          });
          console.log(newFormData[formKey]);
          setFormData(newFormData);
        } catch (error) {
          console.log(error);
        }
      }

      function handleAddTag(formKey, addedTag) {
        try {
          const newFormData = { ...formData };
          if (!Array.isArray(newFormData[formKey])) {
            newFormData[formKey] = [];
          }
          newFormData[formKey].push(addedTag);
          setFormData(newFormData);
        } catch (error) {
          console.log(error);
        }
      }

      function dragEnd(result, key) {
        // dropped outside the list
        const newFormData = { ...formData };
        if (!Array.isArray(newFormData[key])) {
          newFormData[key] = [];
        }
        if (!result.destination) {
          return;
        }

        const items = reorder(
          newFormData[key],
          result.source.index,
          result.destination.index
        );

        newFormData[key] = items;
        setFormData(newFormData);
      }

      return (
        <>
          <Row gutter={[16, 16]} align="middle">
            <Col>
              <Title level={4}>상품 관리</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={20}>
              <Text>
                {formData.name}({formData._id})
              </Text>
            </Col>
            <Col xs={24} sm={4} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  Modal.info({
                    title: "JSON",
                    content: <pre>{JSON.stringify(formData, null, 2)}</pre>,
                    onOk() {}
                  });
                }}
                icon="database"
              >
                JSON 보기...
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <br />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 4 },
                  md: { span: 2 },
                  style: { textAlign: "left" }
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 20 },
                  md: { span: 22 }
                }}
              >
                {/* // "_id": "5dae67cf7006cf29dcb09978",
                    // "type": 0,
                    // "title": "test",
                    // "content": "tteesstt",
                    // "created_at": "2019-10-22T02:22:07.844Z",
                    // "updated_at": "2019-10-22T02:22:07.844Z",
                    // "__v": 0 */}
                <Form.Item label="상품 코드">
                  <Text>{formData.code}</Text>
                </Form.Item>
                <Form.Item label="상품 유형">
                  <Select
                    name="type"
                    style={{ width: "100%", maxWidth: 200 }}
                    value={formData.type}
                    onChange={handleSelectChange}
                  >
                    {productTypes.map((value, index) => {
                      return (
                        <Select.Option value={index}>{value}</Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="상품명">
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    style={{ maxWidth: 400 }}
                  />
                </Form.Item>
                <Form.Item label="상품 태그">
                  {Array.isArray(formData.tags) &&
                    formData.tags.map(text => {
                      return (
                        <Tag
                          closable
                          onClose={e => {
                            e.preventDefault();
                            handleCloseTag("tags", text);
                            // return false;
                          }}
                        >
                          {text}
                        </Tag>
                      );
                    })}
                  <Input.Search
                    style={{ maxWidth: 100 }}
                    value={newTagValue}
                    onChange={event => {
                      setNewTagValue(event.target.value);
                    }}
                    enterButton="+"
                    onSearch={(value, event) => {
                      handleAddTag("tags", value);
                      setNewTagValue("");
                    }}
                  />
                </Form.Item>
                <Form.Item label="공개 여부">
                  <Select
                    name="isPublic"
                    style={{ width: "100%", maxWidth: 200 }}
                    value={formData.isPublic}
                    onChange={handleSelectChange}
                  >
                    {/* eslint-disable-next-line react/jsx-boolean-value */}
                    <Select.Option value={true}>공개</Select.Option>
                    <Select.Option value={false}>비공개</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="판매가">
                  <InputNumber
                    name="price"
                    value={formData.price}
                    onChange={value => {
                      setStateKeyValue("price", value);
                    }}
                    style={{ maxWidth: 200 }}
                  />
                  원
                </Form.Item>

                <Form.Item label="판매 URL">
                  <Input
                    name="url"
                    value={formData.url}
                    onChange={handleChange}
                    style={{ maxWidth: 800 }}
                  />
                </Form.Item>

                <Form.Item label="대표 이미지">
                  {/* <Input
                    name="main_image"
                    value={formData.main_image}
                    onChange={handleChange}
                    style={{ maxWidth: 400 }}
                  /> */}

                  {formData.main_image && (
                    <>
                      <img
                        src={formData.main_image}
                        alt="banner"
                        style={{
                          width: "100%",
                          maxWidth: 320,
                          maxHeight: 320,
                          objectFit: "contain",
                          objectPosition: "0 0"
                        }}
                      />
                      &nbsp;
                      <Button
                        icon="minus"
                        type="danger"
                        onClick={() => {
                          setStateKeyValue("main_image", "");
                        }}
                        style={{ verticalAlign: "top" }}
                      />
                      <br />
                    </>
                  )}
                  <Upload
                    name="main_image"
                    // listType="picture-card"
                    showUploadList={false}
                    // action={
                    //   process.env.REACT_APP_API_URL + "/api/v1/files/upload"
                    // }
                    // headers={{ "x-access-token": auth.user.token }}
                    // onChange={console.log}
                    // onChange={handleFileChange}
                    beforeUpload={file => {
                      uploadFiles(file, "main_image", true);
                      return false;
                    }}
                  >
                    <Button>
                      <Icon type="plus" /> 클릭하여 업로드
                    </Button>
                  </Upload>
                </Form.Item>
                {!createMode && (
                  <>
                    <Form.Item label="생성일">
                      <Text>
                        {new Date(formData.created_at).toLocaleString()}
                      </Text>
                    </Form.Item>
                    <Form.Item label="최종 수정일">
                      <Text>
                        {new Date(formData.updated_at).toLocaleString()}
                      </Text>
                    </Form.Item>
                  </>
                )}
                <Form.Item label="추가 이미지">
                  {/* {Array.isArray(formData.sub_images) &&
                    formData.sub_images.map(text => {
                      return (
                        <>
                          <Tag
                            closable
                            onClose={() => {
                              handleCloseTag("sub_images", text);
                            }}
                          >
                            {text}
                          </Tag>
                          <br />
                        </>
                      );
                    })}
                  <Input.Search
                    style={{ maxWidth: 400 }}
                    enterButton="+"
                    onSearch={value => {
                      handleAddTag("sub_images", value);
                    }}
                  /> */}

                  <DragDropContext
                    onDragEnd={result => {
                      dragEnd(result, "sub_images");
                    }}
                  >
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >
                          {formData &&
                            formData.sub_images &&
                            formData.sub_images.map((item, index) => (
                              <Draggable
                                key={`subimage${item}`}
                                draggableId={`subimage${item}`}
                                index={index}
                              >
                                {(provided2, snapshot2) => (
                                  <div
                                    ref={provided2.innerRef}
                                    {...provided2.draggableProps}
                                    {...provided2.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot2.isDragging,
                                      provided2.draggableProps.style
                                    )}
                                  >
                                    <img
                                      src={item}
                                      alt="banner"
                                      style={{
                                        width: "100%",
                                        maxWidth: 240,
                                        maxHeight: 240,
                                        objectFit: "contain",
                                        objectPosition: "0 0"
                                      }}
                                    />
                                    &nbsp;
                                    <Button
                                      icon="minus"
                                      type="danger"
                                      onClick={() => {
                                        const newFormData = { ...formData };
                                        newFormData.sub_images.splice(index, 1);
                                        setFormData(newFormData);
                                      }}
                                      style={{ verticalAlign: "top" }}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <Upload
                    name="sub_images"
                    // listType="picture-card"
                    showUploadList={false}
                    // action={
                    //   process.env.REACT_APP_API_URL + "/api/v1/files/upload"
                    // }
                    // headers={{ "x-access-token": auth.user.token }}
                    // onChange={console.log}
                    // onChange={handleFileChange}
                    multiple
                    beforeUpload={file => {
                      uploadFiles(file, "sub_images", false);
                      return false;
                    }}
                  >
                    <Button>
                      <Icon type="plus" /> 클릭하여 업로드
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item label="상품 설명">
                  <Input.TextArea
                    name="summary"
                    value={formData.summary}
                    onChange={handleChange}
                    rows={2}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      상품 상세
                      <br />
                      (HTML)
                    </>
                  }
                >
                  <Input.TextArea
                    name="detail"
                    value={formData.detail}
                    onChange={handleChange}
                    rows={10}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col style={{ textAlign: "right" }}>
              <Button type="primary" icon="save" onClick={saveData}>
                저장
              </Button>
            </Col>
          </Row>
        </>
      );
    })
  )
);
