import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Link, Route } from "react-router-dom";
import {
  Typography,
  Row,
  Col,
  Input,
  Button,
  Select,
  Table,
  Popconfirm,
  Radio,
  Icon,
  message
} from "antd";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  Label,
  ResponsiveContainer
} from "recharts";

import api from "utils/api";

const comma = require("comma-number");

const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;

const videoTypes = [
  "자기이해",
  "진로탐색",
  "선택과 확신",
  "실행과 달성",
  "진로 재점검",
  "인생계획"
];
const Report = inject("auth")(
  inject("routing")(
    observer(({ auth, match, routing }) => {
      const [loading, setLoading] = useState(false);
      const [data, setData] = useState([]);
      const [sumUsers, setSumUsers] = useState(0);
      const [queryStrings, setQueryStrings] = useState({
        range: "monthly",
        type: null
      });
      const [displayKeys, setDisplayKeys] = useState([
        { dataKey: "total", fill: "#8884d8" }
      ]);
      const [selectedAge, setSelectedAge] = useState("~20");
      const [showAgeRadio, setShowAgeRadio] = useState(false);
      const fetchReportData = async () => {
        try {
          setLoading(true);
          const { range, type } = queryStrings;
          const query = {};
          if (range) {
            query.range = range;
          }
          if (type) {
            query.type = type;
          }

          const result = await api(auth.user.token).reports.users(query);

          if (result && result.reports && Array.isArray(result.reports)) {
            result.reports = result.reports.map(_item => {
              const item = { ..._item };
              if (queryStrings.range === "weekly") {
                item.name = `${item._id.year}. ${item._id.month} ${item._id.week}째 주`;
              } else {
                item.name = `${item._id.year}. ${item._id.month}`;
              }
              return item;
            });
            result.reports.reverse();

            if(!sumUsers){
              const sum = result.reports.reduce((a, b) => {
                return parseInt(a, 10) + parseInt(b.total, 10);
              }, 0);
              setSumUsers(sum);

            }
            setData(result.reports);
          }

          console.log(result);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      useEffect(() => {
        fetchReportData();
      }, [queryStrings]);

      return (
        <>
          <Row gutter={[16, 16]}>
            <Col>
              <Title level={4}>유형 별 참여자 수</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Select
                name="range"
                value={queryStrings.range}
                onChange={value => {
                  const newQueryStrings = { ...queryStrings };
                  newQueryStrings.range = value;
                  setQueryStrings(newQueryStrings);
                }}
                style={{ width: "100%" }}
              >
                <Option value="monthly">월 단위</Option>
                <Option value="weekly">주 단위</Option>
              </Select>
            </Col>
            <Col span={4}>
              <Select
                name="type"
                value={queryStrings.type}
                onChange={value => {
                  const newQueryStrings = { ...queryStrings };
                  newQueryStrings.type = value;
                  setQueryStrings(newQueryStrings);
                }}
                style={{ width: "100%" }}
              >
                <Option value={null}>모든 유형</Option>
                {videoTypes.map((value, index) => (
                  <Option value={index}>{value}</Option>
                ))}
              </Select>
            </Col>
            <Col span={4}>
              <Select
                name="type"
                defaultValue="total"
                onChange={value => {
                  const displayType = {
                    total: [{ dataKey: "total", fill: "#75787b" }],
                    gender: [
                      { dataKey: "male", fill: "#0085CA" },
                      { dataKey: "female", fill: "#EF3340" }
                    ],
                    age: [{ dataKey: "~20", fill: "#75787b" }]
                  };
                  const newDisplayKeys = displayType[value];
                  if (value === "age") {
                    setSelectedAge("~20");
                    setShowAgeRadio(true);
                  } else {
                    setShowAgeRadio(false);
                  }
                  setDisplayKeys(newDisplayKeys);
                }}
                style={{ width: "100%" }}
              >
                <Option value="total">모든 참여자</Option>
                <Option value="gender">성별</Option>
                <Option value="age">연령대별</Option>
              </Select>
            </Col>
            {showAgeRadio && (
              <Col span={12}>
                <Radio.Group
                  onChange={e => {
                    const newSelectedAge = e.target.value;
                    setDisplayKeys([
                      { dataKey: e.target.value, fill: "#75787b" }
                    ]);
                    setSelectedAge(newSelectedAge);
                  }}
                  value={selectedAge}
                >
                  <Radio value="~20">20세 이하</Radio>
                  <Radio value="21~24">21~24세</Radio>
                  <Radio value="25~29">25~29세</Radio>
                  <Radio value="30~34">30~34세</Radio>
                  <Radio value="35~39">35~39세</Radio>
                  <Radio value="40~">40세 이상</Radio>
                </Radio.Group>
              </Col>
            )}
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <br />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <div style={{ width: "100%", height: "60vh" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis type="number" domain={[0, sumUsers]} />
                    <Tooltip />
                    <Legend
                      align="right"
                      verticalAlign="bottom"
                      content={props => {
                        const { payload } = props;

                        return (
                          <div style={{ textAlign: "right" }}>
                            <span
                              style={{ fontWeight: "700", marginTop: ".5em" }}
                            >
                              누적
                            </span>
                            {payload.map((entry, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <span
                                style={{
                                  display: "block",
                                  marginTop: ".3em"
                                }}
                              >
                                {entry.value}:&nbsp;
                                {data.reduce(
                                  (a, b) =>
                                    parseInt(a, 10) +
                                    parseInt(b[entry.value], 10),
                                  0
                                )}
                              </span>
                            ))}
                          </div>
                        );
                      }}
                    />
                    {/* <Bar dataKey="male" fill="#8884d8" />
                  <Bar dataKey="female" fill="#82ca9d" /> */}
                    {Array.isArray(data) && data.length > 0 && (
                      <Brush dataKey="name" height={25} stroke="#8884d8" />
                    )}
                    {displayKeys.map(item => (
                      <Bar {...item} />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Col>
          </Row>
          {loading && (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "fixed",
                left: 0,
                top: 0,
                opacity: 0.5,
                backgroundColor: "#777777",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 300
              }}
            >
              <Icon
                type="loading"
                style={{ fontSize: 48, color: "lightblue" }}
              />
            </div>
          )}
        </>
      );
    })
  )
);

export default ({ match }) => (
  <>
    <Route path={`${match.path}`} exact component={Report} />
  </>
);
