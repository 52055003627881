import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Link, Route } from "react-router-dom";
import {
  Typography,
  Row,
  Col,
  Input,
  Button,
  Select,
  Table,
  Popconfirm,
  message
} from "antd";

import api from "utils/api";
import Board from "./Board";

const comma = require("comma-number");

const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;

const List = inject("auth")(
  inject("routing")(
    observer(({ auth, match, routing }) => {
      const [data, setData] = useState([]);
      const [queryStrings, setQueryStrings] = useState({});
      const [pagination, setPagination] = useState({
        pageSize: 10,
        total: 0
      });
      const [selectedData, setSelectedData] = useState([]);
      async function fetchBoardList(page = 1, limit = 10) {
        const result = await api(auth.user.token).boards.list({
          page,
          limit,
          ...queryStrings
        });

        const newPagination = { ...pagination };
        newPagination.total = result.boards.totalDocs;

        setPagination(newPagination);
        setData(result.boards.docs);
      }

      async function deleteSelectedData() {
        try {
          const promises = selectedData.map(async value => {
            // eslint-disable-next-line no-param-reassign
            await api(auth.user.token).boards.delete({ bid: value._id });
          });

          await Promise.all(promises);
          message.success("완료되었습니다.");
          fetchBoardList(pagination.current, pagination.pageSize);
        } catch (error) {
          message.error(JSON.stringify(error, null, 4));
        }
      }

      useEffect(() => {
        fetchBoardList();
      }, [queryStrings]);
      const columns = [
        {
          title: "주소",
          dataIndex: "slug",
          key: "slug",
          render: (text, record) => {
            return <Link to={`${match.url}/${record._id}`}>{text}</Link>;
          }
        },
        {
          title: "제목",
          dataIndex: "name",
          key: "name",
          render: (text, record) => {
            return <Link to={`${match.url}/${record._id}`}>{text}</Link>;
          }
        },
        {
          title: "읽기 권한",
          dataIndex: "readPermission",
          key: "readPermission",
          render: value => {
            return Array.isArray(value) ? value.join(" | ") : "";
          }
        },
        {
          title: "쓰기 권한",
          dataIndex: "writePermission",
          key: "writePermission",
          render: value => {
            return Array.isArray(value) ? value.join(" | ") : "";
          }
        },
        {
          title: "게시물 수",
          dataIndex: "count",
          key: "count",
          render: comma
        },
        // {
        //   title: "생성일",
        //   dataIndex: "created_at",
        //   key: "created_at",
        //   render: value => {
        //     return new Date(value).toLocaleString();
        //   }
        // },
        // {
        //   title: "수정일",
        //   dataIndex: "updated_at",
        //   key: "updated_at",
        //   render: value => {
        //     return new Date(value).toLocaleString();
        //   }
        // },
        {
          title: "",
          dataIndex: "_id",
          key: "_id",
          render: bid => {
            return (
              <>
                <Link to={`/boards/${bid}/articles`}>
                  <Button type="primary">게시물 관리</Button>
                </Link>
              </>
            );
          }
        }
      ];
      const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedData(selectedRows);
        },
        getCheckboxProps: record => ({
          disabled: record.isDropped, // Column configuration not to be checked
          name: record.name
        })
      };

      function handleTableChange(pager, filters, sorter) {
        fetchBoardList(pager.current, pager.pageSize);
      }

      return (
        <>
          <Row gutter={[16, 16]}>
            <Col>
              <Title level={4}>게시판 리스트</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Search
                placeholder="검색... "
                onSearch={value => {
                  const newQueryStrings = { ...queryStrings };
                  newQueryStrings.search = value;
                  setQueryStrings(newQueryStrings);
                }}
              />
            </Col>
            <Col span={18} style={{ textAlign: "right" }}>
              <Link to={`${match.url}/new`}>
                <Button icon="plus" type="primary">
                  게시판 등록
                </Button>
              </Link>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            {/* <Col span={4}>
              <Select style={{ width: "100%" }} defaultValue="">
                <Option value="">모든 타입</Option>
                {boardTypes.map((value, index) => {
                  return (
                    <Option value={index} disabled>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Col> */}
            <Col span={8}>
              <Text>전체 {pagination.total}개</Text>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Table
                dataSource={data}
                columns={columns}
                rowSelection={rowSelection}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Popconfirm
                title="정말 삭제하겠습니까?"
                onConfirm={deleteSelectedData}
                okText="예"
                cancelText="아니오"
              >
                <Button icon="delete" type="danger">
                  선택 삭제
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </>
      );
    })
  )
);

export default ({ match }) => (
  <>
    <Route path={`${match.path}`} exact component={List} />
    <Route path={`${match.path}/:bid`} component={Board} />
  </>
);
