import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  Form, //
  Input,
  Button,
  Row,
  Col,
  Select,
  Typography,
  Modal,
  message
} from "antd";

import api from "utils/api";

const { Text, Title } = Typography;
const questionTypes = [
  "자기이해",
  "진로탐색",
  "선택과 확신",
  "실행과 달성",
  "진로 재점검",
  "인생계획"
];

export default inject("auth")(
  inject("routing")(
    observer(({ match, auth, routing }) => {
      const [formData, setFormData] = useState({});
      const [createMode, setCreateMode] = useState(false);

      async function fetchData(qid) {
        const result = await api(auth.user.token).questions.findOne({ qid });

        if (result.question) {
          setFormData(result.question);
        }
      }

      async function saveData() {
        let result = {};

        if (createMode) {
          result = await api(auth.user.token).questions.create(formData);
        } else {
          result = await api(auth.user.token).questions.update({
            ...formData,
            qid: match.params.qid
          });
        }
        console.log(result);
        if (result.question) {
          message.success("저장되었습니다.");
          routing.push("/test/questions");
        } else {
          message.error(JSON.stringify(result, null, 2));
        }
      }

      useEffect(() => {
        if (match.params.qid === "new") {
          setCreateMode(true);
        } else {
          fetchData(match.params.qid);
        }
      }, []);

      function handleChange(e) {
        const { value, name } = e.target;
        const newFormData = { ...formData };
        newFormData[name] = value;
        setFormData(newFormData);
      }

      function handleSelectChange(value) {
        const newFormData = { ...formData };
        newFormData[this.name] = value;
        setFormData(newFormData);
      }

      return (
        <>
          <Row gutter={[16, 16]} align="middle">
            <Col>
              <Title level={4}>유형 테스트 질문 관리</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={20}>
              <Text>
                {formData.title}({formData._id})
              </Text>
            </Col>
            <Col xs={24} sm={4} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  Modal.info({
                    title: "JSON",
                    content: <pre>{JSON.stringify(formData, null, 2)}</pre>,
                    onOk() {}
                  });
                }}
                icon="database"
              >
                JSON 보기...
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <br />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 4 },
                  md: { span: 2 },
                  style: { textAlign: "left" }
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 20 },
                  md: { span: 22 }
                }}
              >
                {/* // "_id": "5dae67cf7006cf29dcb09978",
                    // "type": 0,
                    // "title": "test",
                    // "content": "tteesstt",
                    // "created_at": "2019-10-22T02:22:07.844Z",
                    // "updated_at": "2019-10-22T02:22:07.844Z",
                    // "__v": 0 */}
                <Form.Item label="유형 구분">
                  <Select
                    name="type"
                    style={{ width: "100%", maxWidth: 200 }}
                    value={formData.type}
                    onChange={handleSelectChange}
                  >
                    {questionTypes.map((value, index) => {
                      return (
                        <Select.Option value={index}>{value}</Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="질문 제목">
                  <Input
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    style={{ maxWidth: 400 }}
                  />
                </Form.Item>
                <Form.Item label="생성일">
                  <Text>{new Date(formData.created_at).toLocaleString()}</Text>
                </Form.Item>
                <Form.Item label="최종 수정일">
                  <Text>{new Date(formData.updated_at).toLocaleString()}</Text>
                </Form.Item>
                <Form.Item label="질문 내용">
                  <Input
                    name="content"
                    value={formData.content}
                    onChange={handleChange}
                    style={{ maxWidth: 400 }}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col style={{ textAlign: "right" }}>
              <Button type="primary" icon="save" onClick={saveData}>
                저장
              </Button>
            </Col>
          </Row>
        </>
      );
    })
  )
);
