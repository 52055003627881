import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  Form, //
  Input,
  Button,
  Row,
  Col,
  Select,
  Typography,
  Modal,
  Tag,
  InputNumber,
  Table,
  Checkbox,
  message
} from "antd";

import api from "utils/api";

const comma = require("comma-number");

const { Text, Title } = Typography;

const boardTypes = [
  "자기이해",
  "진로탐색",
  "선택과 확신",
  "실행과 달성",
  "진로 재점검",
  "인생계획"
];

const userTypes = [
  { label: "관리자", value: "admin" },
  { label: "회원", value: "user" },
  { label: "비회원", value: "guest" }
];

const boardCodes = [];

for (let i = 1; i <= 6; i++) {
  for (let j = 1; j <= 6; j++) {
    boardCodes.push(`board_${("0" + i).slice(-2)}_${("0" + j).slice(-2)}`);
  }
}

export default inject("auth")(
  inject("routing")(
    observer(({ match, auth, routing }) => {
      const [formData, setFormData] = useState({});
      const [createMode, setCreateMode] = useState(false);

      async function fetchData(bid) {
        const result = await api(auth.user.token).boards.findOne({ bid });

        if (result.board) {
          setFormData(result.board);
        }
      }

      async function saveData() {
        let result = {};

        if (createMode) {
          result = await api(auth.user.token).boards.create(formData);
        } else {
          result = await api(auth.user.token).boards.update({
            ...formData,
            bid: match.params.bid
          });
        }
        console.log(result);
        if (result.board) {
          message.success("저장되었습니다.");
          routing.push("/boards");
        } else {
          message.error(JSON.stringify(result, null, 2));
        }
      }

      useEffect(() => {
        if (match.params.bid === "new") {
          setCreateMode(true);
        } else {
          fetchData(match.params.bid);
        }
      }, []);

      function handleChange(e) {
        const { value, name } = e.target;
        const newFormData = { ...formData };
        newFormData[name] = value;
        setFormData(newFormData);
      }

      function handleSelectChange(value) {
        const newFormData = { ...formData };
        newFormData[this.name] = value;
        setFormData(newFormData);
      }

      function setStateKeyValue(key, value) {
        const newFormData = { ...formData };
        newFormData[key] = value;
        setFormData(newFormData);
      }

      function handleCloseTag(formKey, removedTag) {
        // const tags = this.state.tags.filter(tag => tag !== removedTag);
        // console.log(tags);
        // this.setState({ tags });
        try {
          const newFormData = { ...formData };

          newFormData[formKey] = newFormData[formKey].filter(
            tag => tag !== removedTag
          );
          setFormData(newFormData);
        } catch (error) {
          console.log(error);
        }
      }

      function handleAddTag(formKey, addedTag) {
        try {
          const newFormData = { ...formData };
          if (!Array.isArray(newFormData[formKey])) {
            newFormData[formKey] = [];
          }
          newFormData[formKey].push(addedTag);
          setFormData(newFormData);
        } catch (error) {
          console.log(error);
        }
      }

      const columns = [
        {
          title: "회원 이메일",
          dataIndex: "user",
          key: "user",
          render: user => {
            return user && user.email;
          }
        },
        {
          title: "내용",
          dataIndex: "content",
          key: "content"
        },
        {
          title: "생성일",
          dataIndex: "created_at",
          key: "created_at",
          render: value => {
            return new Date(value).toLocaleString();
          }
        },
        {
          title: "유형",
          dataIndex: "isSecret",
          key: "isSecret",
          render: value => {
            return value ? "비밀" : "공개";
          }
        },
        {
          title: "PK",
          dataIndex: "_id",
          key: "_id"
        }
      ];

      function toString(obj) {
        let result = "";
        if (typeof obj === "string") {
          result = obj;
        } else if (!obj) {
          result = "";
        } else {
          result = "" + obj;
        }
        return result;
      }

      return (
        <>
          <Row gutter={[16, 16]} align="middle">
            <Col>
              <Title level={4}>게시판 관리</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={20}>
              <Text>
                {formData.vcode}({formData._id})
              </Text>
            </Col>
            <Col xs={24} sm={4} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  Modal.info({
                    title: "JSON",
                    content: <pre>{JSON.stringify(formData, null, 2)}</pre>,
                    onOk() {}
                  });
                }}
                icon="database"
              >
                JSON 보기...
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <br />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 6 },
                  md: { span: 4 },
                  style: { textAlign: "left" }
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 18 },
                  md: { span: 20 }
                }}
              >
                <Form.Item label="게시판 주소">
                  <Input
                    name="slug"
                    value={formData.slug}
                    onChange={handleChange}
                    style={{ maxWidth: 400 }}
                  />
                </Form.Item>
                <Form.Item label="게시판 이름">
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    style={{ maxWidth: 800 }}
                  />
                </Form.Item>
                <Form.Item label="읽기 권한">
                  <Checkbox.Group
                    options={userTypes}
                    value={formData.readPermission}
                    onChange={value => {
                      setStateKeyValue("readPermission", value);
                    }}
                  />
                </Form.Item>
                <Form.Item label="쓰기 권한">
                  <Checkbox.Group
                    options={userTypes}
                    value={formData.writePermission}
                    onChange={value => {
                      setStateKeyValue("writePermission", value);
                    }}
                  />
                </Form.Item>

                {!createMode && (
                  <>
                    <Form.Item label="생성일">
                      <Text>
                        {new Date(formData.created_at).toLocaleString()}
                      </Text>
                    </Form.Item>
                    <Form.Item label="최종 수정일">
                      <Text>
                        {new Date(formData.updated_at).toLocaleString()}
                      </Text>
                    </Form.Item>
                    <Form.Item label="게시물 수">
                      <Text>{comma(formData.count)}</Text>
                    </Form.Item>
                  </>
                )}

                <Form.Item
                  label={`게시판 설명 [ ${
                    toString(formData.description).length
                  } / 100 ]`}
                >
                  <Input.TextArea
                    rows={4}
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col style={{ textAlign: "right" }}>
              <Button type="primary" icon="save" onClick={saveData}>
                저장
              </Button>
            </Col>
          </Row>
        </>
      );
    })
  )
);
