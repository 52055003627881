import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  Form, //
  Input,
  Button,
  Row,
  Col,
  Select,
  Typography,
  Modal,
  message,
  Tag,
  InputNumber,
  Table
} from "antd";

import api from "utils/api";

const comma = require("comma-number");

const { Text, Title } = Typography;

const videoTypes = [
  "자기이해",
  "진로탐색",
  "선택과 확신",
  "실행과 달성",
  "진로 재점검",
  "인생계획"
];

const videoCodes = [];

for (let i = 1; i <= 6; i++) {
  for (let j = 1; j <= 6; j++) {
    videoCodes.push(`video_${("0" + i).slice(-2)}_${("0" + j).slice(-2)}`);
  }
}

export default inject("auth")(
  inject("routing")(
    observer(({ match, auth, routing }) => {
      const [formData, setFormData] = useState({});
      const [createMode, setCreateMode] = useState(false);

      async function fetchData(vid) {
        const result = await api(auth.user.token).videos.findOne({ vid });

        if (result.video) {
          setFormData(result.video);
        }
      }

      async function saveData() {
        let result = {};

        if (createMode) {
          result = await api(auth.user.token).videos.create(formData);
        } else {
          result = await api(auth.user.token).videos.update({
            ...formData,
            vid: match.params.vid
          });
        }
        console.log(result);
        if (result.video) {
          message.success("저장되었습니다.");
          routing.push("/videos");
        } else {
          message.error(JSON.stringify(result, null, 2));
        }
      }

      useEffect(() => {
        if (match.params.vid === "new") {
          setCreateMode(true);
        } else {
          fetchData(match.params.vid);
        }
      }, []);

      function handleChange(e) {
        const { value, name } = e.target;
        const newFormData = { ...formData };
        newFormData[name] = value;
        setFormData(newFormData);
      }

      function handleSelectChange(value) {
        const newFormData = { ...formData };
        newFormData[this.name] = value;
        setFormData(newFormData);
      }

      function setStateKeyValue(key, value) {
        const newFormData = { ...formData };
        newFormData[key] = value;
        setFormData(newFormData);
      }

      function handleCloseTag(formKey, removedTag) {
        // const tags = this.state.tags.filter(tag => tag !== removedTag);
        // console.log(tags);
        // this.setState({ tags });
        try {
          const newFormData = { ...formData };

          newFormData[formKey] = newFormData[formKey].filter(
            tag => tag !== removedTag
          );
          setFormData(newFormData);
        } catch (error) {
          console.log(error);
        }
      }

      function handleAddTag(formKey, addedTag) {
        try {
          const newFormData = { ...formData };
          if (!Array.isArray(newFormData[formKey])) {
            newFormData[formKey] = [];
          }
          newFormData[formKey].push(addedTag);
          setFormData(newFormData);
        } catch (error) {
          console.log(error);
        }
      }

      const columns = [
        {
          title: "회원 이메일",
          dataIndex: "user",
          key: "user",
          render: user => {
            return user && user.email;
          }
        },
        {
          title: "내용",
          dataIndex: "content",
          key: "content"
        },
        {
          title: "생성일",
          dataIndex: "created_at",
          key: "created_at",
          render: value => {
            return new Date(value).toLocaleString();
          }
        },
        {
          title: "유형",
          dataIndex: "isSecret",
          key: "isSecret",
          render: value => {
            return value ? "비밀" : "공개";
          }
        },
        {
          title: "PK",
          dataIndex: "_id",
          key: "_id"
        }
      ];

      function toString(obj) {
        let result = "";
        if (typeof obj === "string") {
          result = obj;
        } else if (!obj) {
          result = "";
        } else {
          result = "" + obj;
        }
        return result;
      }

      return (
        <>
          <Row gutter={[16, 16]} align="middle">
            <Col>
              <Title level={4}>영상 관리</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={20}>
              <Text>
                {formData.vcode}({formData._id})
              </Text>
            </Col>
            <Col xs={24} sm={4} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  Modal.info({
                    title: "JSON",
                    content: <pre>{JSON.stringify(formData, null, 2)}</pre>,
                    onOk() {}
                  });
                }}
                icon="database"
              >
                JSON 보기...
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <br />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 6 },
                  md: { span: 4 },
                  style: { textAlign: "left" }
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 18 },
                  md: { span: 20 }
                }}
              >
                {/* // "_id": "5dae67cf7006cf29dcb09978",
                    // "type": 0,
                    // "title": "test",
                    // "content": "tteesstt",
                    // "created_at": "2019-10-22T02:22:07.844Z",
                    // "updated_at": "2019-10-22T02:22:07.844Z",
                    // "__v": 0 */}
                <Form.Item label="영상 코드">
                  <Select
                    name="vcode"
                    style={{ width: "100%", maxWidth: 200 }}
                    value={formData.vcode}
                    onChange={handleSelectChange}
                  >
                    {videoCodes.map(value => {
                      return (
                        <Select.Option value={value}>{value}</Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="클래스">
                  <Select
                    name="type"
                    style={{ width: "100%", maxWidth: 200 }}
                    value={formData.type}
                    onChange={handleSelectChange}
                  >
                    {videoTypes.map((value, index) => {
                      return (
                        <Select.Option value={index}>{value}</Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="제목">
                  <Input
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    style={{ maxWidth: 400 }}
                  />
                </Form.Item>

                <Form.Item label="공개 여부">
                  <Select
                    name="isActive"
                    style={{ width: "100%", maxWidth: 200 }}
                    value={formData.isActive}
                    onChange={handleSelectChange}
                  >
                    {/* eslint-disable-next-line react/jsx-boolean-value */}
                    <Select.Option value={true}>공개</Select.Option>
                    <Select.Option value={false}>비공개</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="파일 주소">
                  <Input
                    name="url"
                    value={formData.url}
                    onChange={handleChange}
                    style={{ maxWidth: 800 }}
                  />
                </Form.Item>

                {!createMode && (
                  <>
                    <Form.Item label="생성일">
                      <Text>{new Date(formData.created_at).toLocaleString()}</Text>
                    </Form.Item>
                    <Form.Item label="최종 수정일">
                      <Text>{new Date(formData.updated_at).toLocaleString()}</Text>
                    </Form.Item>
                    <Form.Item label="조회수">
                      <Text>{comma(formData.hits)}</Text>
                    </Form.Item>
                  </>
                )}

                <Form.Item
                  label={`상세 설명 [ ${
                    toString(formData.description).length
                  } / 300 ]`}
                >
                  <Input.TextArea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    rows={10}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col style={{ textAlign: "right" }}>
              <Button type="primary" icon="save" onClick={saveData}>
                저장
              </Button>
            </Col>
          </Row>
          {!createMode && (
            <>
              <Row gutter={[16, 16]}>
                <Col>
                  <Text strong>댓글 내역</Text>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Table
                    dataSource={formData.comments}
                    columns={columns}
                    // rowSelection={rowSelection}
                    // pagination={pagination}
                    // onChange={handleTableChange}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      );
    })
  )
);
