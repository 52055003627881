import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Link, Route } from "react-router-dom";
import {
  Typography,
  Row,
  Col,
  Input,
  Button,
  Select,
  Table,
  Popconfirm,
  Radio,
  DatePicker,
  Icon,
  message
} from "antd";
import moment from "moment";
import "moment/locale/ko";

import api from "utils/api";

const comma = require("comma-number");

const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

const videoTypes = [
  "자기이해",
  "진로탐색",
  "선택과 확신",
  "실행과 달성",
  "진로 재점검",
  "인생계획"
];
const Report = inject("auth")(
  inject("routing")(
    observer(({ auth, match, routing }) => {
      const [loading, setLoading] = useState(false);
      const [data, setData] = useState([]);
      const [sumUsers, setSumUsers] = useState(1);
      const [queryStrings, setQueryStrings] = useState({
        start: new Date("2000-01-01"),
        end: new Date()
      });
      const [displayKey, setDisplayKey] = useState("total");
      const [isRadioShown, setRadioShown] = useState(false);
      const fetchReportData = async () => {
        try {
          setLoading(true);
          const { start, end } = queryStrings;
          const sort = displayKey;
          const query = {};
          if (start) {
            query.start = start;
          }
          if (end) {
            query.end = end;
          }
          if (sort) {
            query.sort = sort;
          }

          const result = await api(auth.user.token).reports.types(query);

          setData(result.reports);

          console.log(result);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      useEffect(() => {
        fetchReportData();
      }, [queryStrings, displayKey]);

      useEffect(() => {
        if (data && Array.isArray(data) && data.length > 0) {
          if (displayKey) {
            const sum = data.reduce((a, b) => {
              return parseInt(a, 10) + parseInt(b[displayKey], 10);
            }, 0);
            setSumUsers(sum);
          }
        }
      }, [displayKey, data]);

      return (
        <>
          <Row gutter={[16, 16]}>
            <Col>
              <Title level={4}>조합 순위</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              기간 검색:&nbsp;
              <DatePicker
                value={moment(queryStrings.start, dateFormat).utc(true)}
                format={dateFormat}
                onChange={date => {
                  try {
                    const newQueryStrings = { ...queryStrings };
                    newQueryStrings.start = date.toJSON();
                    setQueryStrings(newQueryStrings);
                  } catch (error) {
                    console.log(error);
                  }
                }}
                allowClear={false}
              />
              &nbsp;-&nbsp;
              <DatePicker
                value={moment(queryStrings.end, dateFormat).utc(true)}
                format={dateFormat}
                onChange={date => {
                  try {
                    const newQueryStrings = { ...queryStrings };
                    newQueryStrings.end = date.toJSON();
                    setQueryStrings(newQueryStrings);
                  } catch (error) {
                    console.log(error);
                  }
                }}
                allowClear={false}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Select
                name="type"
                defaultValue="total"
                onChange={value => {
                  switch (value) {
                    case "total":
                      setRadioShown(false);
                      setDisplayKey("total");
                      break;
                    case "age":
                      setRadioShown("age");
                      setDisplayKey("~20");
                      break;
                    case "gender":
                      setRadioShown("gender");
                      setDisplayKey("male");
                      break;

                    default:
                      break;
                  }
                }}
                style={{ width: "100%" }}
              >
                <Option value="total">모든 참여자</Option>
                <Option value="gender">성별</Option>
                <Option value="age">연령대별</Option>
              </Select>
            </Col>
            {isRadioShown === "age" && (
              <Col span={12}>
                <Radio.Group
                  onChange={e => {
                    const newSelectedAge = e.target.value;
                    setDisplayKey(newSelectedAge);
                  }}
                  value={displayKey}
                >
                  <Radio value="~20">20세 이하</Radio>
                  <Radio value="21~24">21~24세</Radio>
                  <Radio value="25~29">25~29세</Radio>
                  <Radio value="30~34">30~34세</Radio>
                  <Radio value="35~39">35~39세</Radio>
                  <Radio value="40~">40세 이상</Radio>
                </Radio.Group>
              </Col>
            )}
            {isRadioShown === "gender" && (
              <Col span={12}>
                <Radio.Group
                  onChange={e => {
                    const newSelectedGender = e.target.value;
                    setDisplayKey(newSelectedGender);
                  }}
                  value={displayKey}
                >
                  <Radio value="male">남성</Radio>
                  <Radio value="female">여성</Radio>
                </Radio.Group>
              </Col>
            )}
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <br />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Table
                columns={[
                  {
                    title: "순위",
                    // dataIndex: "_id",
                    // key: "_id",
                    render: (text, record, index) => {
                      return `${index + 1}위`;
                    }
                  },

                  {
                    title: "유형 조합",
                    dataIndex: "_id",
                    key: "_id",
                    render: _id => {
                      return _id.types
                        .map(value => videoTypes[parseInt(value, 10)])
                        .join("　　　");
                    }
                  },
                  {
                    title: "참여자 수",
                    dataIndex: displayKey,
                    key: "amount",
                    render: text => {
                      return comma(text);
                    }
                  },
                  {
                    title: "백분율",
                    dataIndex: displayKey,
                    key: "percentage",
                    render: value => {
                      console.log(value);
                      console.log(sumUsers);
                      return (
                        parseFloat(
                          (parseInt(value, 10) / sumUsers) * 100,
                          10
                        ).toFixed(1) + "%"
                      );
                    }
                  }
                ]}
                dataSource={data}
                pagination={false}
              />
            </Col>
          </Row>
          {loading && (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "fixed",
                left: 0,
                top: 0,
                opacity: 0.5,
                backgroundColor: "#777777",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 300
              }}
            >
              <Icon
                type="loading"
                style={{ fontSize: 48, color: "lightblue" }}
              />
            </div>
          )}
        </>
      );
    })
  )
);

export default ({ match }) => (
  <>
    <Route path={`${match.path}`} exact component={Report} />
  </>
);
