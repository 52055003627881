import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Link, Route } from "react-router-dom";
import {
  Typography,
  Row,
  Col,
  Input,
  Button,
  Select,
  Table,
  Popconfirm,
  message
} from "antd";

import api from "utils/api";
import User from "./User";

const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;

const List = inject("auth")(
  inject("routing")(
    observer(({ auth, match, routing }) => {
      const [data, setData] = useState([]);
      const [queryStrings, setQueryStrings] = useState({});
      const [pagination, setPagination] = useState({
        pageSize: 10,
        total: 0
      });
      const [selectedData, setSelectedData] = useState([]);
      async function fetchUserList(page = 1, limit = 10) {
        const result = await api(auth.user.token).users.list({
          page,
          limit,
          ...queryStrings
        });

        console.log(result);
        const newPagination = { ...pagination };
        newPagination.total = result.users.totalDocs;

        setPagination(newPagination);
        setData(result.users.docs);
      }

      async function deleteSelectedData() {
        try {
          const promises = selectedData.map(async value => {
            // eslint-disable-next-line no-param-reassign
            value.isDropped = true;
            await api(auth.user.token).users.update({
              ...value,
              uid: value._id
            });
          });

          await Promise.all(promises);
          message.success("완료되었습니다.");
          fetchUserList(pagination.current, pagination.pageSize);
        } catch (error) {
          message.error(JSON.stringify(error, null, 4));
        }
      }

      useEffect(() => {
        fetchUserList();
      }, [queryStrings]);
      const columns = [
        {
          title: "이메일",
          dataIndex: "email",
          key: "email",
          render: (text, record) => {
            return <Link to={`${match.url}/${record._id}`}>{text}</Link>;
          }
        },
        {
          title: "이름",
          dataIndex: "name",
          key: "name"
        },
        {
          title: "권한",
          dataIndex: "userType",
          key: "userType",
          render: value => {
            switch (value) {
              case "admin":
                return "관리자";

              case "user":
                return "일반 회원";

              default:
                return "?";
            }
          }
        },
        {
          title: "상태",
          dataIndex: "isDropped",
          key: "isDropped",
          render: value => {
            return value ? "탈퇴" : "정상";
          }
        },
        {
          title: "회원가입일",
          dataIndex: "created_at",
          key: "created_at",
          render: value => {
            return new Date(value).toLocaleString();
          }
        },
        {
          title: "최종로그인",
          dataIndex: "last_signin",
          key: "last_signin",
          render: value => {
            return new Date(value).toLocaleString();
          }
        },
        {
          title: "PK",
          dataIndex: "_id",
          key: "_id"
        }
      ];
      const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          setSelectedData(selectedRows);
        },
        getCheckboxProps: record => ({
          disabled: record.isDropped, // Column configuration not to be checked
          name: record.name
        })
      };

      function handleTableChange(pager, filters, sorter) {
        fetchUserList(pager.current, pager.pageSize);
      }

      return (
        <>
          <Row gutter={[16, 16]}>
            <Col>
              <Title level={4}>회원 리스트</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Search
                placeholder="검색... "
                onSearch={value => {
                  const newQueryStrings = { ...queryStrings };
                  newQueryStrings.search = value;
                  setQueryStrings(newQueryStrings);
                }}
              />
            </Col>
            <Col span={18} style={{ textAlign: "right" }}>
              <Link to={`${match.url}/new`}>
                <Button icon="plus" type="primary">
                  회원 등록
                </Button>
              </Link>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Select
                style={{ width: "100%" }}
                defaultValue={null}
                onChange={value => {
                  const newQueryStrings = { ...queryStrings };
                  newQueryStrings.type = value;
                  setQueryStrings(newQueryStrings);
                }}
              >
                <Option value={null}>모든 유저</Option>
                <Option value="active">활동 유저</Option>
                <Option value="inactive">휴면 유저</Option>
                <Option value="out">탈퇴 유저</Option>
              </Select>
            </Col>
            <Col span={8}>
              <Text>전체 {pagination.total}명</Text>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Table
                dataSource={data}
                columns={columns}
                rowSelection={rowSelection}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Popconfirm
                title="정말 삭제하겠습니까?"
                onConfirm={deleteSelectedData}
                okText="예"
                cancelText="아니오"
              >
                <Button icon="delete" type="danger">
                  선택 탈퇴처리
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </>
      );
    })
  )
);

export default ({ match }) => (
  <>
    <Route path={`${match.path}`} exact component={List} />
    <Route path={`${match.path}/:uid`} component={User} />
  </>
);
