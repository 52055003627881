import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  Form, //
  Input,
  Button,
  Row,
  Col,
  Select,
  Typography,
  Modal,
  Tag,
  InputNumber,
  Table,
  Checkbox,
  Switch,
  message
} from "antd";

import api from "utils/api";

const comma = require("comma-number");

const { Text, Title } = Typography;

export default inject("auth")(
  inject("routing")(
    observer(({ match, auth, routing }) => {
      const bid = match.params.bid;
      const [formData, setFormData] = useState({});
      const [createMode, setCreateMode] = useState(false);

      async function fetchData(aid) {
        const result = await api(auth.user.token).articles.findOne({ aid });

        if (result.article) {
          setFormData(result.article);
        }
      }

      async function saveData() {
        let result = {};

        if (createMode) {
          result = await api(auth.user.token).articles.create({
            bid,
            user: auth.user._id,
            board: bid,
            ...formData
          });
        } else {
          result = await api(auth.user.token).articles.update({
            ...formData,
            bid,
            aid: match.params.aid
          });
        }
        console.log(result);
        if (result.article) {
          message.success("저장되었습니다.");
          routing.push(
            match.url
              .split("/")
              .slice(0, -1)
              .join("/")
          );
        } else {
          message.error(JSON.stringify(result, null, 2));
        }
      }

      useEffect(() => {
        if (match.params.aid === "new") {
          setCreateMode(true);
        } else {
          fetchData(match.params.aid);
        }
      }, []);

      function handleChange(e) {
        // eslint-disable-next-line prefer-const
        let { value, name } = e.target;
        const newFormData = { ...formData };
        if (value === "true") {
          value = true;
        } else if (value === "false") {
          value = false;
        }
        newFormData[name] = value;
        setFormData(newFormData);
      }

      function handleSelectChange(value) {
        const newFormData = { ...formData };
        newFormData[this.name] = value;
        setFormData(newFormData);
      }

      function setStateKeyValue(key, value) {
        const newFormData = { ...formData };
        newFormData[key] = value;
        setFormData(newFormData);
      }

      function handleCloseTag(formKey, removedTag) {
        // const tags = this.state.tags.filter(tag => tag !== removedTag);
        // console.log(tags);
        // this.setState({ tags });
        try {
          const newFormData = { ...formData };

          newFormData[formKey] = newFormData[formKey].filter(
            tag => tag !== removedTag
          );
          setFormData(newFormData);
        } catch (error) {
          console.log(error);
        }
      }

      function handleAddTag(formKey, addedTag) {
        try {
          const newFormData = { ...formData };
          if (!Array.isArray(newFormData[formKey])) {
            newFormData[formKey] = [];
          }
          newFormData[formKey].push(addedTag);
          setFormData(newFormData);
        } catch (error) {
          console.log(error);
        }
      }

      function toString(obj) {
        let result = "";
        if (typeof obj === "string") {
          result = obj;
        } else if (!obj) {
          result = "";
        } else {
          result = "" + obj;
        }
        return result;
      }

      return (
        <>
          <Row gutter={[16, 16]} align="middle">
            <Col>
              <Title level={4}>게시물 관리</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={20}>
              <Text>
                {formData.vcode}({formData._id})
              </Text>
            </Col>
            <Col xs={24} sm={4} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  Modal.info({
                    title: "JSON",
                    content: <pre>{JSON.stringify(formData, null, 2)}</pre>,
                    onOk() {}
                  });
                }}
                icon="database"
              >
                JSON 보기...
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <br />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 6 },
                  md: { span: 4 },
                  style: { textAlign: "left" }
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 18 },
                  md: { span: 20 }
                }}
              >
                <Form.Item label="제목">
                  <Input
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    style={{ maxWidth: 800 }}
                  />
                </Form.Item>
                <Form.Item label="작성자">
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    style={{ maxWidth: 400 }}
                  />
                </Form.Item>
                <Form.Item label="공개 여부">
                  <Switch
                    name="isPublic"
                    checked={formData.isPublic}
                    // def  aultChecked={formData.isPublic}
                    onChange={(checked, e) => {
                      e.target.value = checked;
                      handleChange(e);
                    }}
                  />
                </Form.Item>

                {!createMode && (
                  <>
                    <Form.Item label="생성일">
                      <Text>
                        {new Date(formData.created_at).toLocaleString()}
                      </Text>
                    </Form.Item>
                    <Form.Item label="최종 수정일">
                      <Text>
                        {new Date(formData.updated_at).toLocaleString()}
                      </Text>
                    </Form.Item>
                    <Form.Item label="조회수">
                      <Text>{comma(formData.hits)}</Text>
                    </Form.Item>
                  </>
                )}

                <Form.Item
                  label={`게시물 내용 [ ${
                    toString(formData.content).length
                  } / 500 ]`}
                >
                  <Input.TextArea
                    rows={10}
                    name="content"
                    value={formData.content}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col style={{ textAlign: "right" }}>
              <Button type="primary" icon="save" onClick={saveData}>
                저장
              </Button>
            </Col>
          </Row>
        </>
      );
    })
  )
);
