import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  Form, //
  Input,
  Button,
  Row,
  Col,
  Select,
  Typography,
  Modal,
  Switch,
  Upload,
  Icon,
  message
} from "antd";

import api from "utils/api";

const { Text, Title } = Typography;
const questionTypes = [
  "자기이해",
  "진로탐색",
  "선택과 확신",
  "실행과 달성",
  "진로 재점검",
  "인생계획"
];

export default inject("auth")(
  inject("routing")(
    observer(({ match, auth, routing }) => {
      const [formData, setFormData] = useState({});
      const [createMode, setCreateMode] = useState(false);

      async function fetchData(rid) {
        const result = await api(auth.user.token).results.findOne({ rid });

        if (result.result) {
          setFormData(result.result);
        }
      }

      async function saveData() {
        let result = {};

        if (createMode) {
          result = await api(auth.user.token).results.create(formData);
        } else {
          result = await api(auth.user.token).results.update({
            ...formData,
            rid: match.params.rid
          });
        }
        console.log(result);
        if (result.result) {
          message.success("저장되었습니다.");
          routing.push("/test/results");
        } else {
          message.error(JSON.stringify(result, null, 2));
        }
      }

      useEffect(() => {
        if (match.params.rid === "new") {
          setCreateMode(true);
        } else {
          fetchData(match.params.rid);
        }
      }, []);

      function toString(obj) {
        let result = "";
        if (typeof obj === "string") {
          result = obj;
        } else if (!obj) {
          result = "";
        } else {
          result = "" + obj;
        }
        return result;
      }

      function handleChange(e) {
        // eslint-disable-next-line prefer-const
        let { value, name } = e.target;
        const newFormData = { ...formData };
        if (value === "true") {
          value = true;
        } else if (value === "false") {
          value = false;
        }
        newFormData[name] = value;
        setFormData(newFormData);
      }

      function setStateKeyValue(key, value) {
        const newFormData = { ...formData };
        newFormData[key] = value;
        setFormData(newFormData);
      }

      async function uploadFiles(files, key, isSingle) {
        const uploaded = await api(auth.user.token).files.upload(files);
        console.log(uploaded);

        const newFormData = { ...formData };
        if (isSingle) {
          newFormData[key] = uploaded.files[0].location;
        } else {
          if (!Array.isArray(newFormData[key])) {
            newFormData[key] = [];
          }
          newFormData[key].push(
            ...uploaded.files.map(({ location }) => {
              return location;
            })
          );
        }
        setFormData(newFormData);
      }
      function handleSelectChange(value) {
        const newFormData = { ...formData };
        newFormData[this.name] = value;
        setFormData(newFormData);
      }

      return (
        <>
          <Row gutter={[16, 16]} align="middle">
            <Col>
              <Title level={4}>유형 테스트 결과 관리</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={20}>
              <Text>
                {formData.title}({formData._id})
              </Text>
            </Col>
            <Col xs={24} sm={4} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  Modal.info({
                    title: "JSON",
                    content: <pre>{JSON.stringify(formData, null, 2)}</pre>,
                    onOk() {}
                  });
                }}
                icon="database"
              >
                JSON 보기...
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <br />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 4 },
                  md: { span: 2 },
                  style: { textAlign: "left" }
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 20 },
                  md: { span: 22 }
                }}
              >
                {/* // "_id": "5dae67cf7006cf29dcb09978",
                    // "type": 0,
                    // "title": "test",
                    // "content": "tteesstt",
                    // "created_at": "2019-10-22T02:22:07.844Z",
                    // "updated_at": "2019-10-22T02:22:07.844Z",
                    // "__v": 0 */}
                <Form.Item label="유형 구분">
                  <Select
                    name="type"
                    style={{ width: "100%", maxWidth: 200 }}
                    value={formData.type}
                    onChange={handleSelectChange}
                  >
                    {questionTypes.map((value, index) => {
                      return (
                        <Select.Option value={index}>{value}</Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="제목">
                  <Input
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    style={{ maxWidth: 400 }}
                  />
                </Form.Item>
                {!createMode && (
                  <>
                    <Form.Item label="생성일">
                      <Text>
                        {new Date(formData.created_at).toLocaleString()}
                      </Text>
                    </Form.Item>
                    <Form.Item label="최종 수정일">
                      <Text>
                        {new Date(formData.updated_at).toLocaleString()}
                      </Text>
                    </Form.Item>
                  </>
                )}
                <Form.Item label="사용 여부">
                  <Switch
                    name="isActive"
                    checked={formData.isActive}
                    // def  aultChecked={formData.isActive}
                    onChange={(checked, e) => {
                      e.target.value = checked;
                      handleChange(e);
                    }}
                  />
                </Form.Item>
                <Form.Item label="결과 제목">
                  <Input
                    name="resultTitle"
                    value={formData.resultTitle}
                    onChange={handleChange}
                    style={{ maxWidth: 400 }}
                  />
                </Form.Item>
                <Form.Item label="결과 이미지">
                  {/* <Input
                    name="resultImage"
                    value={formData.resultImage}
                    onChange={handleChange}
                    style={{ maxWidth: 400 }}
                  /> */}

                  {formData.resultImage && (
                    <>
                      <img
                        src={formData.resultImage}
                        alt="banner"
                        style={{
                          width: "100%",
                          maxWidth: 320,
                          maxHeight: 320,
                          objectFit: "contain",
                          objectPosition: "0 0"
                        }}
                      />
                      &nbsp;
                      <Button
                        icon="minus"
                        type="danger"
                        onClick={() => {
                          setStateKeyValue("resultImage", "");
                        }}
                        style={{ verticalAlign: "top" }}
                      />
                      <br />
                    </>
                  )}
                  <Upload
                    name="resultImage"
                    // listType="picture-card"
                    showUploadList={false}
                    // action={
                    //   process.env.REACT_APP_API_URL + "/api/v1/files/upload"
                    // }
                    // headers={{ "x-access-token": auth.user.token }}
                    // onChange={console.log}
                    // onChange={handleFileChange}
                    beforeUpload={file => {
                      uploadFiles(file, "resultImage", true);
                      return false;
                    }}
                  >
                    <Button>
                      <Icon type="plus" /> 클릭하여 업로드
                    </Button>
                  </Upload>
                </Form.Item>

                <Form.Item label="결과 텍스트">
                  <Form.Item label="설명 1">
                    <Input
                      name="explain1"
                      value={formData.explain1}
                      onChange={handleChange}
                      style={{ maxWidth: 400 }}
                    />
                  </Form.Item>

                  <Form.Item label="설명 2">
                    <Input
                      name="explain2"
                      value={formData.explain2}
                      onChange={handleChange}
                      style={{ maxWidth: 400 }}
                    />
                  </Form.Item>
                  <Form.Item label="유형 키워드">
                    <Input
                      name="typeKeyword"
                      value={formData.typeKeyword}
                      onChange={handleChange}
                      style={{ maxWidth: 400 }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={`유형 상세 설명 [ ${
                      toString(formData.typeDetail).length
                    } / 300 ]`}
                  >
                    <Input.TextArea
                      name="typeDetail"
                      value={formData.typeDetail}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  <Form.Item label="해결책 키워드">
                    <Input
                      name="solutionKeyword"
                      value={formData.solutionKeyword}
                      onChange={handleChange}
                      style={{ maxWidth: 400 }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={`해결책 상세 설명 [ ${
                      toString(formData.solutionDetail).length
                    } / 300 ]`}
                  >
                    <Input.TextArea
                      name="solutionDetail"
                      value={formData.solutionDetail}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  <Form.Item label="모험 키워드">
                    <Input
                      name="adventureKeyword"
                      value={formData.adventureKeyword}
                      onChange={handleChange}
                      style={{ maxWidth: 400 }}
                    />
                  </Form.Item>
                  <Form.Item label="모험 이미지">
                    {/* <Input
                      name="adventureImage"
                      value={formData.adventureImage}
                      onChange={handleChange}
                      style={{ maxWidth: 400 }}
                    /> */}

                    {formData.adventureImage && (
                      <>
                        <img
                          src={formData.adventureImage}
                          alt="banner"
                          style={{
                            width: "100%",
                            maxWidth: 320,
                            maxHeight: 320,
                            objectFit: "contain",
                            objectPosition: "0 0"
                          }}
                        />
                        &nbsp;
                        <Button
                          icon="minus"
                          type="danger"
                          onClick={() => {
                            setStateKeyValue("adventureImage", "");
                          }}
                          style={{ verticalAlign: "top" }}
                        />
                        <br />
                      </>
                    )}
                    <Upload
                      name="adventureImage"
                      // listType="picture-card"
                      showUploadList={false}
                      // action={
                      //   process.env.REACT_APP_API_URL + "/api/v1/files/upload"
                      // }
                      // headers={{ "x-access-token": auth.user.token }}
                      // onChange={console.log}
                      // onChange={handleFileChange}
                      beforeUpload={file => {
                        uploadFiles(file, "adventureImage", true);
                        return false;
                      }}
                    >
                      <Button>
                        <Icon type="plus" /> 클릭하여 업로드
                      </Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    label={`모험 상세 설명 [ ${
                      toString(formData.adventureDetail).length
                    } / 300 ]`}
                  >
                    <Input.TextArea
                      name="adventureDetail"
                      value={formData.adventureDetail}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item label="상품 코드">
                  <Input
                    name="product"
                    value={formData.product}
                    onChange={handleChange}
                    style={{ maxWidth: 400 }}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col style={{ textAlign: "right" }}>
              <Button type="primary" icon="save" onClick={saveData}>
                저장
              </Button>
            </Col>
          </Row>
        </>
      );
    })
  )
);
