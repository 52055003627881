import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Link, Route } from "react-router-dom";
import {
  Typography,
  Row,
  Col,
  Input,
  Button,
  Select,
  Table,
  Popconfirm,
  message
} from "antd";

import api from "utils/api";
import Product from "./Product";

const comma = require("comma-number");

const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;

const productTypes = ["진로 코칭 플래너", "노트/메모지", "카드/엽서"];
const List = inject("auth")(
  inject("routing")(
    observer(({ auth, match, routing }) => {
      const [data, setData] = useState([]);
      const [queryStrings, setQueryStrings] = useState({});
      const [pagination, setPagination] = useState({
        pageSize: 10,
        total: 0
      });
      const [selectedData, setSelectedData] = useState([]);
      async function fetchProductList(page = 1, limit = 10) {
        const result = await api(auth.user.token).products.list({
          page,
          limit,
          ...queryStrings
        });

        // "_id": "5dae67cf7006cf29dcb09978",
        // "type": 0,
        // "title": "test",
        // "content": "tteesstt",
        // "created_at": "2019-10-22T02:22:07.844Z",
        // "updated_at": "2019-10-22T02:22:07.844Z",
        // "__v": 0
        console.log(result);
        const newPagination = { ...pagination };
        newPagination.total = result.products.totalDocs;

        setPagination(newPagination);
        setData(result.products.docs);
      }

      async function deleteSelectedData() {
        try {
          const promises = selectedData.map(async value => {
            // eslint-disable-next-line no-param-reassign
            await api(auth.user.token).products.delete({ rid: value._id });
          });

          await Promise.all(promises);
          message.success("완료되었습니다.");
          fetchProductList(pagination.current, pagination.pageSize);
        } catch (error) {
          message.error(JSON.stringify(error, null, 4));
        }
      }

      useEffect(() => {
        fetchProductList();
      }, [queryStrings]);
      const columns = [
        {
          title: "상품 코드",
          dataIndex: "code",
          key: "code"
        },
        {
          title: "상품 구분",
          dataIndex: "type",
          key: "type",
          render: text => {
            return productTypes[parseInt(text, 10)];
          }
        },
        {
          title: "상품명",
          dataIndex: "name",
          key: "name",
          render: (text, record) => {
            return <Link to={`${match.url}/${record._id}`}>{text}</Link>;
          }
        },
        {
          title: "금액",
          dataIndex: "price",
          key: "price",
          render: text => {
            return comma(parseInt(text, 10));
          }
        },
        {
          title: "생성일",
          dataIndex: "created_at",
          key: "created_at",
          render: value => {
            return new Date(value).toLocaleString();
          }
        },
        {
          title: "수정일",
          dataIndex: "updated_at",
          key: "updated_at",
          render: value => {
            return new Date(value).toLocaleString();
          }
        },
        {
          title: "공개 여부",
          dataIndex: "isPublic",
          key: "isPublic",
          render: value => {
            return value ? "공개" : "비공개";
          }
        },
        {
          title: "PK",
          dataIndex: "_id",
          key: "_id"
        }
      ];
      const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          setSelectedData(selectedRows);
        },
        getCheckboxProps: record => ({
          disabled: record.isDropped, // Column configuration not to be checked
          name: record.name
        })
      };

      function handleTableChange(pager, filters, sorter) {
        fetchProductList(pager.current, pager.pageSize);
      }

      return (
        <>
          <Row gutter={[16, 16]}>
            <Col>
              <Title level={4}>상품 리스트</Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Search
                placeholder="검색... "
                onSearch={value => {
                  const newQueryStrings = { ...queryStrings };
                  newQueryStrings.search = value;
                  setQueryStrings(newQueryStrings);
                }}
              />
            </Col>
            <Col span={18} style={{ textAlign: "right" }}>
              <Link to={`${match.url}/new`}>
                <Button icon="plus" type="primary">
                  상품 등록
                </Button>
              </Link>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Select
                style={{ width: "100%" }}
                defaultValue={null}
                onChange={value => {
                  const newQueryStrings = { ...queryStrings };
                  newQueryStrings.type = value;
                  setQueryStrings(newQueryStrings);
                }}
              >
                <Option value={null}>모든 타입</Option>
                {productTypes.map((value, index) => {
                  return <Option value={index}>{value}</Option>;
                })}
              </Select>
            </Col>
            <Col span={8}>
              <Text>전체 {pagination.total}개</Text>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Table
                dataSource={data}
                columns={columns}
                rowSelection={rowSelection}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Popconfirm
                title="정말 삭제하겠습니까?"
                onConfirm={deleteSelectedData}
                okText="예"
                cancelText="아니오"
              >
                <Button icon="delete" type="danger">
                  선택 삭제
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </>
      );
    })
  )
);

export default ({ match }) => (
  <>
    <Route path={`${match.path}`} exact component={List} />
    <Route path={`${match.path}/:rid`} component={Product} />
  </>
);
