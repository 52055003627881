import React, { useState } from "react";
import { Layout, Menu, Icon } from "antd";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

const { SubMenu } = Menu;
const { Sider } = Layout;

export default inject("routing")(
  observer(({ routing }) => {
    const [collapsed, setCollapsed] = useState(false);

    return (
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        width={200}
        style={{ background: "#fff" }}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={[routing.location.pathname]}
          defaultOpenKeys={[]}
          style={{ height: "100%", borderRight: 0 }}
        >
          <Menu.Item key="/users">
            <Icon type="user" />
            <span>회원</span>
            <Link to="/users" />
          </Menu.Item>
          <SubMenu
            key="menu2"
            title={
              <span>
                <Icon type="laptop" />
                <span>유형 테스트</span>
              </span>
            }
          >
            <Menu.Item key="/test/questions">
              <Link to="/test/questions" />
              <span>질문 리스트</span>
            </Menu.Item>
            <Menu.Item key="/test/results">
              <Link to="/test/results" />
              <span>결과 리스트</span>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="menu3"
            title={
              <span>
                <Icon type="video-camera" />
                <span>영상 코칭 가이드</span>
              </span>
            }
          >
            <Menu.Item key="/videos">
              <Link to="/videos" />
              <span>영상 리스트</span>
            </Menu.Item>
            <Menu.Item key="/comments">
              <Link to="/comments" />
              <span>댓글 리스트</span>
            </Menu.Item>
            <Menu.Item key="/books">
              <Link to="/books" />
              <span>북코드 리스트</span>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="menu4"
            title={
              <span>
                <Icon type="shopping" />
                <span>상품</span>
              </span>
            }
          >
            <Menu.Item key="/products">
              <Link to="/products" />
              <span>상품 리스트</span>
            </Menu.Item>
            <Menu.Item key="/banners">
              <Link to="/banners" />
              <span>배너 리스트</span>
            </Menu.Item>
          </SubMenu>

          <Menu.Item key="/boards">
            <Icon type="profile" />
            <span>게시판</span>
            <Link to="/boards" />
          </Menu.Item>

          <SubMenu
            key="menu6"
            title={
              <span>
                <Icon type="bar-chart" />
                <span>통계</span>
              </span>
            }
          >
            <Menu.Item key="/reports/users">
              <Link to="/reports/users" />
              <span>유형 별 참여자 수</span>
            </Menu.Item>
            <Menu.Item key="/reports/type">
              <Link to="/reports/type" />
              <span>유형 순위</span>
            </Menu.Item>
            <Menu.Item key="/reports/question">
              <Link to="/reports/question" />
              <span>질문 순위</span>
            </Menu.Item>
            <Menu.Item key="/reports/types">
              <Link to="/reports/types" />
              <span>유형 조합 순위</span>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
    );
  })
);
